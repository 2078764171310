import { MCBHS320DeleteAutoSubmissionRequest } from 'types/MCBHS320/MCBHS320DeleteAutoSubmissionRequest'
import { MCBHS320InitDisplayRequest } from 'types/MCBHS320/MCBHS320InitDisplayRequest'
import { MCBHS320ReloadSubmissionsSetRequest } from 'types/MCBHS320/MCBHS320ReloadSubmissionsSetRequest'
import { MCBHS320SubmissionRequestTemplateInputRequest } from 'types/MCBHS320/MCBHS320SubmissionRequestTemplateInputRequest'
import { MCBHS320SubmitRequestPreviewRequest } from 'types/MCBHS320/MCBHS320SubmitRequestPreviewRequest'
import request from 'utils/request'

export const autoSubmissionRequestUpdApi = (params: MCBHS320InitDisplayRequest) =>
  request({
    url: '/MCBHS320/init',
    method: 'post',
    data: params
  })

export const autoSubmissionRequestConfirm320Api = (requestBody: { registeredFileName: File[], submitParams: MCBHS320SubmissionRequestTemplateInputRequest }) => {
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
    data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
  return request({
    url: '/MCBHS320/confirm',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const deleteAutoSubmissionRequestApi = (params:MCBHS320DeleteAutoSubmissionRequest) =>
  request({
    url: '/MCBHS320/delete',
    method: 'post',
    data: params
  })

export const reloadSubmissionsSetRequest320 = (params: MCBHS320ReloadSubmissionsSetRequest) =>
  request({
    url: '/MCBHS320/reloadSubmissionsSet',
    method: 'post',
    data: params
  })

export const reloadReferenceRequest320 = () =>
  request({
    url: '/MCBHS320/reloadReference',
    method: 'post'
  })

export const reloadThanksTemplateRequest320 = () =>
  request({
    url: '/MCBHS320/reloadThanksTemplate',
    method: 'post'
  })

export const reloadPersonalRequest320 = () =>
  request({
    url: '/MCBHS320/reloadPersonal',
    method: 'post'
  })

export const selectApiLinkRequest320 = () =>
  request({
    url: '/MCBHS320/selectApiLink',
    method: 'post',
  })

export const previewRequest320 = (params: MCBHS320SubmitRequestPreviewRequest) =>
  request({
    url: '/MCBHS320/preview',
    method: 'post',
    data: params
  })

