import { stringToDateTime, now, isEmpty, dateToString } from "common/generalUtil"
import { ConfirmData } from "pages/MCBHS340/formConfig"
import { magiContants } from "utils/contants"
import { MCBHS310EntryBoxInfo, MCBHS310SeminarInfo, suffixCheck } from "./formConfig"
import moment from "moment"

export const handleBusinessCheck = (data: ConfirmData, entryBoxInfoList: MCBHS310EntryBoxInfo[], seminarInfoList: MCBHS310SeminarInfo[]) => {
  if (data.useSetting === 1) {
    const useSettingStartDate = moment(data.useSettingStartDate).toDate()
    const useSettingEndDate = moment(data.useSettingEndDate).toDate()
    // 利用期間逆転チェック
    if (useSettingStartDate >= useSettingEndDate) {
      return { messageId: magiContants.MESSAGECODE_MCBHS310_011, args: [] }
    }
    // 利用期間の自動提出リクエスト利用開始日チェック
    if (!data.isManagementOffice && data.autoSubmissionRequestUseStartTime && data.autoSubmissionRequestUseStartTime.length > 0) {
      let isError = false;
      for (let i = 0; i < data.autoSubmissionRequestUseStartTime.length; i++) {
        let item = data.autoSubmissionRequestUseStartTime[i]
        let startDate
        let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 8) {
          startDate = stringToDateTime(`${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)}`)
        }
        if (startDate && data.targetYear === item.mediaFlag && useSettingStartDate < startDate) {
          isError = true
          break;
        }
      }
      if (isError) {
        return { messageId: magiContants.MESSAGECODE_MCBHS310_013, args: [] }
      }
    }
    // 利用期間のエントリーボックス／セミナー設定条件チェック
    let args = ''
    entryBoxInfoList.forEach(i => {
      if (i.selectFlag) {
        let displayStartDate = stringToDateTime(i.displayStartDate)
        let displayEndDate = stringToDateTime(i.displayEndDate)
        if ((displayEndDate && displayEndDate < useSettingStartDate)
          || (displayStartDate && displayStartDate > useSettingEndDate)) {
          args += '<br />' + entryBoxInfoEdit(i)
        }
      }
    })
    seminarInfoList.forEach(i => {
      if (i.selectFlag) {
        let displayStartDate = stringToDateTime(i.displayStartDate)
        let displayEndDate = stringToDateTime(i.displayEndDate)
        if ((displayEndDate && displayEndDate < useSettingStartDate)
          || (displayStartDate && displayStartDate > useSettingEndDate)) {
          args += '<br />' + seminarInfoEdit(i)
        }
      }
    })
    if (args.length > 0) {
      return { messageId: magiContants.MESSAGECODE_MCBHS310_014, args: [args] }
    }
  }
  return { messageId: '', args: [] }
}

export const handleBusinessCheck2 = (data: ConfirmData, files: File[]) => {
  const systemDate = now()
  // 提出締切チェック
  if (data.limitDateDetermineFlag === 0) {
    let determineDateTime = moment(data.determineDateTime).toDate()
    if (data.useSetting === 1) {
      const useSettingEndDate = moment(data.useSettingEndDate).toDate()
      // 利用終了日時と比較
      if (determineDateTime <= useSettingEndDate) {
        return magiContants.MESSAGECODE_MCBHS310_016
      }
    }
    // システム時間と比較
    if (determineDateTime <= systemDate) {
      return magiContants.MESSAGECODE_MCBHS310_029
    }
  }
  if (data.limitDateDetermineFlag === 2) {
    // 提出締切チェック
    let anytimeReceptionEndDateTime = moment(data.anytimeReceptionEndDateTime).toDate()
    if (data.useSetting === 1) {
      const useSettingEndDate = moment(data.useSettingEndDate).toDate()
      // 利用終了日時と比較
      if (anytimeReceptionEndDateTime <= useSettingEndDate) {
        return magiContants.MESSAGECODE_MCBHS310_017
      }
    }
    // システム時間と比較
    if (anytimeReceptionEndDateTime <= systemDate) {
      return magiContants.MESSAGECODE_MCBHS310_030
    }
    // 再提出の受付可否チェック
    if (data.reRequestReceptFlag === 1) {
      return magiContants.MESSAGECODE_MCBHS310_018
    }
  }
  // 不正文字チェック
  if (null != data.requestSubject && data.requestSubject.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS310_019
  }
  if (null != data.requestText && data.requestText.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS310_019
  }
  // ファイルの拡張子チェック
  for (var i = 0; i < files.length; i++) {
    var lastIndexOf = files[i].name.lastIndexOf('.')
    var suffix = files[i].name.substring(lastIndexOf + 1)
    if (!suffixCheck.includes(suffix.toLowerCase())) {
      return magiContants.MESSAGECODE_MCBHS310_020
    }
  }
  // ファイルのサイズチェック
  for (var i = 0; i < files.length; i++) {
    if (Number(files[i].size) > 10485760) {
      return magiContants.MESSAGECODE_MCBHS310_021
    }
  }
  // 同一添付ファイルチェック
  for (var i = 0; i < files.length; i++) {
    for (var j = 0; j < data.requestAttachmentName.length; j++) {
      if (data.requestAttachmentName[j] === files[i].name) {
        return magiContants.MESSAGECODE_MCBHS310_022
      }
    }
    for (var k = i + 1; k < files.length; k++) {
      if (files[k].name == files[i].name) {
        return magiContants.MESSAGECODE_MCBHS310_022
      }
    }
  }
  // ファイルサイズ下限チェック
  for (var i = 0; i < files.length; i++) {
    const file = files[i]
    if (Number(file.size) == magiContants.NUMBER_0) {
      return magiContants.MESSAGECODE_MCBHS310_023
    }
  }
  // 禁止語チェック
  for (var i = 0; i < data.forbiddenWordsList.length; i++) {
    // 禁止語チェック_件名
    if (data.requestSubject != null && data.requestSubject.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS310_006
    }
    // 禁止語チェック_本文
    if (data.requestText != null && data.requestText.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS310_007
    }
  }
  // ホームページURLの入力チェック
  if (!isEmpty(data.homePageTitle1) && isEmpty(data.homePageUrl1)) {
    return magiContants.MESSAGECODE_MCBHS310_025
  }
  if (!isEmpty(data.homePageTitle2) && isEmpty(data.homePageUrl2)) {
    return magiContants.MESSAGECODE_MCBHS310_025
  }
  if (!isEmpty(data.homePageTitle3) && isEmpty(data.homePageUrl3)) {
    return magiContants.MESSAGECODE_MCBHS310_025
  }
  // 不正文字チェック_（サンクス）
  if (null != data.thanksSubject && data.thanksSubject.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS310_026
  }
  if (null != data.thanksText && data.thanksText.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS310_026
  }
  // 禁止語チェック
  for (var i = 0; i < data.forbiddenWordsList.length; i++) {
    // 禁止語チェック_サンクス件名
    if (data.thanksSubject != null && data.thanksSubject.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS310_008
    }
    // 禁止語チェック_サンクス本文
    if (data.thanksText != null && data.thanksText.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS310_009
    }
  }
  return ''
}

// 27P #10767 start
/* 
 * 「エントリボックスを指定」データの編集
 */
export const entryBoxInfoEdit = (entryBoxInfo: MCBHS310EntryBoxInfo) => {
  let resultText: string = ''
  resultText += entryBoxInfo.coordinationFormerEntryBoxIdentificationKey + '　'
  resultText += entryBoxInfo.entryBoxName + '　'
  let displayStartDate = entryBoxInfo.displayStartDate ? dateToString(stringToDateTime(entryBoxInfo.displayStartDate)) : ''
  let displayEndDate = entryBoxInfo.displayEndDate ? dateToString(stringToDateTime(entryBoxInfo.displayEndDate)) : ''
  if (!isEmpty(displayStartDate) || !isEmpty(displayEndDate)) {
    resultText += '（' + displayStartDate + ' ～ ' + displayEndDate + '）'
  }
  return resultText
}

/* 
 * 「セミナー画面で指定」データの編集
 */
export const seminarInfoEdit = (seminarInfo: MCBHS310SeminarInfo) => {
  let resultText: string = ''
  resultText += seminarInfo.coordinationFormerSeminarIdentificationKey + '　'
  resultText += seminarInfo.seminarName
  if ('0' === seminarInfo.publicFlag) {
    resultText += '【非公開】　'
  } else {
    resultText += '【公開】　'
  }
  let displayStartDate = seminarInfo.displayStartDate ? dateToString(stringToDateTime(seminarInfo.displayStartDate)) : ''
  let displayEndDate = seminarInfo.displayEndDate ? dateToString(stringToDateTime(seminarInfo.displayEndDate)) : ''
  if (!isEmpty(displayStartDate) || !isEmpty(displayEndDate)) {
    resultText += '（' + displayStartDate + ' ～ ' + displayEndDate + '）'
  }
  return resultText
}
// 27P #10767 end