import { stringToDateTime, now, isEmpty, dateToString } from "common/generalUtil"
import moment from "moment"
import { MCBHS310EntryBoxInfo, MCBHS310SeminarInfo, suffixCheck } from "pages/MCBHS310/formConfig"
import { ConfirmData } from "pages/MCBHS340/formConfig"
import { magiContants } from "utils/contants"
import { MCBHS320SendReplyImpossibleTimeDto } from "./formConfig"

export const handle320BusinessCheck = (data: ConfirmData, entryBoxInfoList: MCBHS310EntryBoxInfo[], seminarInfoList: MCBHS310SeminarInfo[]) => {
  if (data.useSetting === 1) {
    const useSettingStartDate = moment(data.useSettingStartDate).toDate()
    const useSettingEndDate = moment(data.useSettingEndDate).toDate()
    // 利用期間逆転チェック
    if (useSettingStartDate >= useSettingEndDate) {
      return { messageId: magiContants.MESSAGECODE_MCBHS320_011, args: [] }
    }
    // 利用期間の自動提出リクエスト利用開始日チェック
    if (!data.isManagementOffice && data.autoSubmissionRequestUseStartTime && data.autoSubmissionRequestUseStartTime.length > 0) {
      let isError = false;
      for (let i = 0; i < data.autoSubmissionRequestUseStartTime.length; i++) {
        let item = data.autoSubmissionRequestUseStartTime[i]
        let startDate
        let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 8) {
          startDate = stringToDateTime(`${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)}`)
        }
        if (startDate && data.targetYear === item.mediaFlag && useSettingStartDate < startDate) {
          isError = true
          break;
        }
      }
      if (isError) {
        return { messageId: magiContants.MESSAGECODE_MCBHS320_013, args: [] }
      }
    }
    // 利用期間のエントリーボックス／セミナー設定条件チェック
    let args = ''
    entryBoxInfoList.forEach(i => {
      if (i.selectFlag) {
        let displayStartDate = stringToDateTime(i.displayStartDate)
        let displayEndDate = stringToDateTime(i.displayEndDate)
        if ((displayEndDate && displayEndDate < useSettingStartDate)
          || (displayStartDate && displayStartDate > useSettingEndDate)) {
          args += '<br />' + entryBoxInfoEdit(i)
        }
      }
    })
    seminarInfoList.forEach(i => {
      if (i.selectFlag) {
        let displayStartDate = stringToDateTime(i.displayStartDate)
        let displayEndDate = stringToDateTime(i.displayEndDate)
        if ((displayEndDate && displayEndDate < useSettingStartDate)
          || (displayStartDate && displayStartDate > useSettingEndDate)) {
          args += '<br />' + seminarInfoEdit(i)
        }
      }
    })
    if (args.length > 0) {
      return { messageId: magiContants.MESSAGECODE_MCBHS320_014, args: [args] }
    }
  }
  return { messageId: '', args: [] }
}

export const handle320BusinessCheck2 = (data: ConfirmData, files: File[]) => {
  const systemDate = now()
  // 提出締切チェック
  if (data.limitDateDetermineFlag === 0) {
    let determineDateTime = moment(data.determineDateTime).toDate()
    if (data.useSetting === 1) {
      const useSettingEndDate = moment(data.useSettingEndDate).toDate()
      // 利用終了日時と比較
      if (determineDateTime <= useSettingEndDate) {
        return magiContants.MESSAGECODE_MCBHS320_017
      }
    }
    // システム時間と比較
    if (determineDateTime <= systemDate) {
      return magiContants.MESSAGECODE_MCBHS320_035
    }
  }
  if (data.limitDateDetermineFlag === 2) {
    // 提出締切チェック
    let anytimeReceptionEndDateTime = moment(data.anytimeReceptionEndDateTime).toDate()
    if (data.useSetting === 1) {
      const useSettingEndDate = moment(data.useSettingEndDate).toDate()
      // 利用終了日時と比較
      if (anytimeReceptionEndDateTime <= useSettingEndDate) {
        return magiContants.MESSAGECODE_MCBHS320_018
      }
    }
    // システム時間と比較
    if (anytimeReceptionEndDateTime <= systemDate) {
      return magiContants.MESSAGECODE_MCBHS320_036
    }
    // 再提出の受付可否チェック
    if (data.reRequestReceptFlag === 1) {
      return magiContants.MESSAGECODE_MCBHS320_019
    }
  }
  // 不正文字チェック
  if (null != data.requestSubject && data.requestSubject.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS320_020
  }
  if (null != data.requestText && data.requestText.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS320_020
  }
  // ファイルの拡張子チェック
  for (var i = 0; i < files.length; i++) {
    var lastIndexOf = files[i].name.lastIndexOf('.')
    var suffix = files[i].name.substring(lastIndexOf + 1)
    if (!suffixCheck.includes(suffix.toLowerCase())) {
      return magiContants.MESSAGECODE_MCBHS320_021
    }
  }
  // ファイルのサイズチェック
  for (var i = 0; i < files.length; i++) {
    if (Number(files[i].size) > 10485760) {
      return magiContants.MESSAGECODE_MCBHS320_022
    }
  }
  // 同一添付ファイルチェック
  for (var i = 0; i < files.length; i++) {
    for (var j = 0; j < data.requestAttachmentName.length; j++) {
      if (data.requestAttachmentName[j] === files[i].name) {
        return magiContants.MESSAGECODE_MCBHS320_023
      }
    }
    for (var k = i + 1; k < files.length; k++) {
      if (files[k].name == files[i].name) {
        return magiContants.MESSAGECODE_MCBHS320_023
      }
    }
  }
  // ファイルサイズ下限チェック
  for (var i = 0; i < files.length; i++) {
    const file = files[i]
    if (Number(file.size) == magiContants.NUMBER_0) {
      return magiContants.MESSAGECODE_MCBHS320_024
    }
  }
  // 禁止語チェック
  for (var i = 0; i < data.forbiddenWordsList.length; i++) {
    // 禁止語チェック_件名
    if (data.requestSubject != null && data.requestSubject.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS320_006
    }
    // 禁止語チェック_本文
    if (data.requestText != null && data.requestText.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS320_007
    }
  }
  // ホームページURLの入力チェック
  if (!isEmpty(data.homePageTitle1) && isEmpty(data.homePageUrl1)) {
    return magiContants.MESSAGECODE_MCBHS320_026
  }
  if (!isEmpty(data.homePageTitle2) && isEmpty(data.homePageUrl2)) {
    return magiContants.MESSAGECODE_MCBHS320_026
  }
  if (!isEmpty(data.homePageTitle3) && isEmpty(data.homePageUrl3)) {
    return magiContants.MESSAGECODE_MCBHS320_026
  }
  // 不正文字チェック_（サンクス）
  if (null != data.thanksSubject && data.thanksSubject.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS320_027
  }
  if (null != data.thanksText && data.thanksText.includes('●●')) {
    return magiContants.MESSAGECODE_MCBHS320_027
  }
  // 禁止語チェック
  for (var i = 0; i < data.forbiddenWordsList.length; i++) {
    // 禁止語チェック_サンクス件名
    if (data.thanksSubject != null && data.thanksSubject.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS320_008
    }
    // 禁止語チェック_サンクス本文
    if (data.thanksText != null && data.thanksText.includes(data.forbiddenWordsList[i])) {
      return magiContants.MESSAGECODE_MCBHS320_009
    }
  }
  return ''
}

// 次の自動提出リクエスト送信時間を取得
export const getNextAutoSendTime = (sendReplyImpossibleTimeList: MCBHS320SendReplyImpossibleTimeDto[]) => {
  const systemDate = now()
  let nextAutoSendTime = calculateNextAutoSendTime(systemDate)
  let firstResult = sendImpossibleTimeCheck(nextAutoSendTime, sendReplyImpossibleTimeList)
  if (firstResult.checkFlag) {
    let inRangeFlag = true
    if (firstResult.resultNextAutoSendTime) {
      nextAutoSendTime = firstResult.resultNextAutoSendTime
    }
    while (inRangeFlag) {
      let afterResult = sendImpossibleTimeCheck(nextAutoSendTime, sendReplyImpossibleTimeList)
      inRangeFlag = afterResult.checkFlag
      if (afterResult.resultNextAutoSendTime) {
        nextAutoSendTime = afterResult.resultNextAutoSendTime
      }
    }
  }
  return nextAutoSendTime.getHours().toString();
}
// 次の自動提出リクエスト送信時間の計算
const calculateNextAutoSendTime = (parameterTime: Date) => {
  const hour = parameterTime.getHours()
  const nextAutoSendTime = parameterTime
  if (hour >= 0 && hour < 10) {
    nextAutoSendTime.setHours(10)
  } else if (hour >= 10 && hour < 13) {
    nextAutoSendTime.setHours(13)
  } else if (hour >= 13 && hour < 17) {
    nextAutoSendTime.setHours(17)
  } else {
    nextAutoSendTime.setDate(nextAutoSendTime.getDate() + 1)
    nextAutoSendTime.setHours(10)
  }
  nextAutoSendTime.setMinutes(0, 0, 0)
  return nextAutoSendTime
}
// 次の自動提出リクエスト送信時間はMCBメッセージ送信不可期間内かどうかを判断する
export const sendImpossibleTimeCheck = (nextAutoSendTime: Date, sendReplyImpossibleTimeList: MCBHS320SendReplyImpossibleTimeDto[]) => {
  let checkFlag = false;
  let resultNextAutoSendTime
  for (var i = 0; i < sendReplyImpossibleTimeList.length; i++) {
    let item = sendReplyImpossibleTimeList[i]
    if (item.functionalDivisionStr === '1' || item.functionalDivisionStr === '3') {
      let startDate
      let endDate
      let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
      let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
      if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
        startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
      }
      if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
        endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
      }
      if (startDate && endDate) {
        if (startDate.toDate() <= nextAutoSendTime && nextAutoSendTime <= endDate.toDate()) {
          resultNextAutoSendTime = calculateNextAutoSendTime(endDate.toDate())
          checkFlag = true
        }
      }
    }
  }
  return {
    checkFlag: checkFlag,
    resultNextAutoSendTime: resultNextAutoSendTime
  }
}

// 利用終了日時より、エントリーを集計するバッチ起動日時を取得する
export const getBatchStartDateTime = (useSettingEndDate: Date) => {
  const batchStartDateTime = useSettingEndDate

  const hours = useSettingEndDate.getHours()
  if (0 <= hours && hours < 8) {
    // 当日00:00＜＝利用終了日時＜当日08:00の場合、当日10:05
    batchStartDateTime.setHours(10);
  } else if (8 <= hours && hours < 11) {
    // 当日08:00＜＝利用終了日時＜当日11:00の場合、当日13:05
    batchStartDateTime.setHours(13);
  } else if (11 <= hours && hours < 15) {
    // 当日11:00＜＝利用終了日時＜当日15:00の場合、当日17:05
    batchStartDateTime.setHours(17);
  } else {
    // 当日15:00＜＝利用終了日時＜当日24:00の場合、次日10:05
    batchStartDateTime.setDate(batchStartDateTime.getDate() + 1)
    batchStartDateTime.setHours(10);
  }
  batchStartDateTime.setMinutes(5, 0, 0);
  return batchStartDateTime
}

// 自動提出リクエストの稼働状態を取得
export const getOperatingStatus = (systemDate: Date, useSettingStartDate: Date, batchStartDateTime: Date) => {
  let operatingStatus = ''
  if (systemDate < useSettingStartDate) {
    // 稼働前：システム時間 ＜ 利用開始日時
    operatingStatus = '1'
  } else if (useSettingStartDate <= systemDate && systemDate < batchStartDateTime) {
    // 稼働中：利用開始日時 ＜＝ システム時間 ＜ 利用終了日時のエントリーを集計するバッチ起動日時
    operatingStatus = '2'
  } else if (batchStartDateTime <= systemDate) {
    // 終了：利用終了日時のエントリーを集計するバッチ起動日時 ＜＝ システム時間
    operatingStatus = '3'
  }
  return operatingStatus
}

// 27P #10767 start
/* 
 * 「エントリボックスを指定」データの編集
 */
export const entryBoxInfoEdit = (entryBoxInfo: MCBHS310EntryBoxInfo) => {
  let resultText: string = ''
  resultText += entryBoxInfo.coordinationFormerEntryBoxIdentificationKey + '　'
  resultText += entryBoxInfo.entryBoxName + '　'
  let displayStartDate = entryBoxInfo.displayStartDate ? dateToString(stringToDateTime(entryBoxInfo.displayStartDate)) : ''
  let displayEndDate = entryBoxInfo.displayEndDate ? dateToString(stringToDateTime(entryBoxInfo.displayEndDate)) : ''
  if (!isEmpty(displayStartDate) || !isEmpty(displayEndDate)) {
    resultText += '（' + displayStartDate + ' ～ ' + displayEndDate + '）'
  }
  return resultText
}

/* 
 * 「セミナー画面で指定」データの編集
 */
export const seminarInfoEdit = (seminarInfo: MCBHS310SeminarInfo) => {
  let resultText: string = ''
  resultText += seminarInfo.coordinationFormerSeminarIdentificationKey + '　'
  resultText += seminarInfo.seminarName
  if ('0' === seminarInfo.publicFlag) {
    resultText += '【非公開】　'
  } else {
    resultText += '【公開】　'
  }
  let displayStartDate = seminarInfo.displayStartDate ? dateToString(stringToDateTime(seminarInfo.displayStartDate)) : ''
  let displayEndDate = seminarInfo.displayEndDate ? dateToString(stringToDateTime(seminarInfo.displayEndDate)) : ''
  if (!isEmpty(displayStartDate) || !isEmpty(displayEndDate)) {
    resultText += '（' + displayStartDate + ' ～ ' + displayEndDate + '）'
  }
  return resultText
}
// 27P #10767 end