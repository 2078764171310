import { MCBHS310InitDisplayRequest } from 'types/MCBHS310/MCBHS310InitDisplayRequest'
import { MCBHS310ReloadSubmissionsSetRequest } from 'types/MCBHS310/MCBHS310ReloadSubmissionsSetRequest'
import { MCBHS310SubmissionRequestTemplateInputRequest } from 'types/MCBHS310/MCBHS310SubmissionRequestTemplateInputRequest'
import { MCBHS310SubmitRequestPreviewRequest } from 'types/MCBHS310/MCBHS310SubmitRequestPreviewRequest'
import request from 'utils/request'

export const autoSubmissionRequestAddApi = (params: MCBHS310InitDisplayRequest) =>
  request({
    url: '/MCBHS310/init',
    method: 'post',
    data: params
  })

export const autoSubmissionRequestConfirmApi = (requestBody: { registeredFileName: File[], submitParams: MCBHS310SubmissionRequestTemplateInputRequest }) => {
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
    data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
  return request({
    url: '/MCBHS310/confirm',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const reloadSubmissionsSetRequest = (params: MCBHS310ReloadSubmissionsSetRequest) =>
  request({
    url: '/MCBHS310/reloadSubmissionsSet',
    method: 'post',
    data: params
  })

export const reloadReferenceRequest = () =>
  request({
    url: '/MCBHS310/reloadReference',
    method: 'post'
  })

export const reloadThanksTemplateRequest = () =>
  request({
    url: '/MCBHS310/reloadThanksTemplate',
    method: 'post'
  })

export const reloadPersonalRequest = () =>
  request({
    url: '/MCBHS310/reloadPersonal',
    method: 'post'
  })

export const selectApiLinkRequest = () =>
  request({
    url: '/MCBHS310/selectApiLink',
    method: 'post',
  })

export const previewRequest = (params: MCBHS310SubmitRequestPreviewRequest) =>
  request({
    url: '/MCBHS310/preview',
    method: 'post',
    data: params
  })

