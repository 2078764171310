import { MCBHS350InitRequest } from 'types/MCBHS350/MCBHS350InitRequest'
import { MCBHS350NewRequest } from 'types/MCBHS350/MCBHS350NewRequest'
import { MCBHS350OverwriteRequest } from 'types/MCBHS350/MCBHS350OverwriteRequest'
import request from 'utils/request'

export const getMaxCountTempApi = (apiData: MCBHS350InitRequest) =>
  request({
    url: `MCBHS350/init`,
    method: 'post',
    data: apiData,
  })

export const saveNewTemplateRequest = (apiData: MCBHS350NewRequest) => {
  const data = new FormData()
  data.append('params', encodeURIComponent(JSON.stringify(apiData)))
  return request({
    url: '/MCBHS350/saveNew',
    method: 'post',
    data: data,
  })
}

export const overwriteTemplateRequest = (apiData: MCBHS350OverwriteRequest) => {

  const data = new FormData()
  data.append('params', encodeURIComponent(JSON.stringify(apiData)))
  return request({
    url: '/MCBHS350/overwrite',
    method: 'post',
    data: data,
  })
}