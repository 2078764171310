
const submitValues = {
  targetYear: '',
};

const initialMCBHS300AutoSubReqResultInfo = {
  searchCondition: submitValues,
  recruitmentManagementDivision: '',
  targetYear: '',
  message: '',
  maxAutoSubReqTemplateCount: '',
  autoSubReqResultList: [],
  // 27P #10663 start
  loadingFlag: false,
  // 27P #10663 end
}

const textMap: { [key: string]: string } = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
}

export { submitValues, initialMCBHS300AutoSubReqResultInfo, textMap };
