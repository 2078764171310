import { MCBHS340InitDisplayRequest } from 'types/MCBHS340/MCBHS340InitDisplayRequest';
import { MCBHS340AutoSubmissionRequestTemplateRequest } from 'types/MCBHS340/MCBHS340AutoSubmissionRequestTemplateRequest';
import request from 'utils/request'

export const initRequest = (params: MCBHS340InitDisplayRequest) =>
  request({
    url: `MCBHS340/init`,
    method: 'post',
    data: params,
  })

export const insertOrUpdateRequest = (requestBody: { registeredFileName: File[], submitParams: MCBHS340AutoSubmissionRequestTemplateRequest }) => {
  const data = new FormData()
  requestBody.registeredFileName.forEach((file) => {
    data.append('files', file);
  });
  data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))

  return request({
    url: `MCBHS340/resister`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}