import {
    MCBHS310EntryBoxInfo, MCBHS310SeminarInfo, MCBHS310SubmissionRequestTemplateInfo, MCBHS310SubmissionsContentInfo,
    MCBHS310ReferenceContentInfo, AttachmentFileInfo, MCBHS310ThanksMessageTemplateInfo, MCBHS310PrivacyPolicyContentInfo,
    AutoSubmissionRequestUseStartTimeDto, MCBHS310SignatureInfo
} from "pages/MCBHS310/formConfig"

/*
 * 自動提出リクエスト編集画面、データ表示用Obj
 */
export interface MCBHS320ReturnDisplayOutDto {
    autoSubmissionRequestSettingId: string // 自動提出リクエスト設定ID
    autoSubmissionRequestName: string // 自動提出リクエスト登録名
    destinationSelection: string	// 送信先区分
    entryBoxList: MCBHS310EntryBoxInfo[] // エントリーボックス情報
    seminarList: MCBHS310SeminarInfo[] // セミナー情報
    entryBoxIds: string[] // 対象者設定(エントリーボックスID)
    seminarIds: string[] // 対象者設定(セミナーID)
    targetCriteria: string // 対象者設定(自動提出リクエスト条件)
    useSetting: string // 利用設定フラグ
    useSettingStartDate: string // 利用設定_利用開始日時
    useSettingEndDate: string // 利用設定_利用終了日時
    submissionRequestTemplateInfoList: MCBHS310SubmissionRequestTemplateInfo[] // 提出リクエストテンプレート情報
    submissionRequestTemplateId: string // 提出リクエストテンプレート選択ID
    submissionRequestTemplateName: string // 提出リクエストテンプレート選択名
    submissionsContentList: MCBHS310SubmissionsContentInfo[] // 提出物内容リスト
    submissionsSelectId: string // 提出物内容選択ID
    submissionsName: string // 提出物内容（企業管理用）
    submissionsNameStudent: string // 提出物内容（学生画面表示用）
    submissionsNames: string // 提出物
    submissionsEntryFlg: string // 提出物エントリシートフラグ
    submissionsResumeFlg: string // 提出物履歴書フラグ
    submissionsResearchFlg: string // 提出物研究概要書フラグ
    submissionsOriginalFlg: string // 提出物オリジナル設問フラグ
    submissionsFileFlg: string // 提出物ファイル提出フラグ
    limitDateDetermineFlag: string // 提出締切_種別
    determineDateTime: string // 提出締切日指定日時
    requestDayTimeLaterDay: string // 依頼日から○日後
    requestDayTimeToTime: string // 依頼日から△:00まで
    anytimeReceptionEndDateTime: string // 随時受付終了日時
    reRequestReceptFlag: string // 再提出受付フラグ
    senderCompanyName: string // 送信元会社名の指定
    senderMailAddress: string // 返信先メールアドレス
    referenceContentList: MCBHS310ReferenceContentInfo[] // 問い合わせ先リスト
    referenceContentId: string // 問い合わせ先選択ID
    referenceContentName: string // 問い合わせ先選択名
    referenceContentCompanyName: string // 問い合わせ先企業名
    referenceContentDepartmentNameAndManagerName: string // 問い合わせ先部署名／担当者氏名
    referenceContentTelephoneNumber: string // 問い合わせ先電話番号
    referenceContentMailAddress: string // 問い合わせ先メールアドレス
    signatureList: MCBHS310SignatureInfo[] // 署名リスト
    requestSubject: string // 件名
    requestText: string // 本文
    attachmentFileList: AttachmentFileInfo[] // 添付ファイル情報
    homePageTitle1: string // ホームページタイトル1
    homePageUrl1: string // ホームページURL1
    homePageTitle2: string // ホームページタイトル2
    homePageUrl2: string // ホームページURL2
    homePageTitle3: string // ホームページタイトル3
    homePageUrl3: string // ホームページURL3
    thanksMessageSetFlag: string // サンクスメッセージ設定フラグ
    thanksMessageTemplateList: MCBHS310ThanksMessageTemplateInfo[] // サンクスメールテンプレート内容リスト
    thanksMessageTemplateId: string // サンクスメールテンプレートID
    thanksMessageTemplateName: string // サンクスメールテンプレート名
    thanksSubject: string // （サンクス）件名
    thanksText: string // （サンクス）本文
    privacyPolicyContentList: MCBHS310PrivacyPolicyContentInfo[] // 個人情報の取り扱い内容リスト
    personalDataHandlingId: string // 個人情報の取り扱い選択ID
    personalDataHandlingName: string // 個人情報の取り扱い名
    sysVersionNumber: string // sysバージョン番号
    exampleLink: string // 文例リンク
    submissionsLink: string // 提出物内容の作成・確認はこちらリンク
    referenceLink: string // 問い合わせ先の作成・確認はこちらリンク
    requestExampleLink: string // 提出リクエストメールの文例集を見るリンク
    thanksMailTemplateLink: string // サンクスメッセージテンプレートの作成・確認はこちらリンク
    thanksMailExampleLink: string // サンクスメッセージの文例集を見るリンク
    personalDataHandlingLink: string // 個人情報の取り扱いの作成・確認はこちらリンク
    authToken: string // MCB認証トークン
    annual: string // 年度
    annualList: string // 対象年度リスト
    annualCs: string // チェックサム
    forbiddenWordsList: string[] // 禁止語リスト
    autoSubmissionRequestUseStartTime: AutoSubmissionRequestUseStartTimeDto[] // 自動提出リクエスト不可期間
    isManagementOffice: boolean // 運営事務局判定
    sendReplyImpossibleTime: MCBHS320SendReplyImpossibleTimeDto[] // MCBメッセージ送信不可期間
}
export interface MCBHS320SendReplyImpossibleTimeDto {
    rowNoStr: string // 項番
    functionalDivisionStr: string //機能区分
    startDateStr: string // 開始日
    endDateStr: string // 終了日
}
export const initMCBHS320DisplayValues: MCBHS320ReturnDisplayOutDto = {
    autoSubmissionRequestSettingId: '', // 自動提出リクエスト設定ID
    autoSubmissionRequestName: '', // 自動提出リクエスト登録名
    destinationSelection: '3', // 送信先区分
    entryBoxList: [], // エントリーボックス情報
    seminarList: [], // セミナー情報
    entryBoxIds: [], // 対象者設定(エントリーボックスID)
    seminarIds: [], // 対象者設定(セミナーID)
    targetCriteria: '', // 対象者設定(自動提出リクエスト条件)
    useSetting: '1', // 利用設定フラグ
    useSettingStartDate: '', // 利用設定_利用開始日時
    useSettingEndDate: '', // 利用設定_利用終了日時
    submissionRequestTemplateInfoList: [], // 提出リクエストテンプレート情報
    submissionRequestTemplateId: '', // 提出リクエストテンプレート選択ID
    submissionRequestTemplateName: '', // 提出リクエストテンプレート選択名
    submissionsContentList: [], // 提出物内容リスト
    submissionsSelectId: '', // 提出物内容選択ID
    submissionsName: '', // 提出物内容（企業管理用）
    submissionsNameStudent: '', // 提出物内容（学生画面表示用）
    submissionsNames: '', // 提出物
    submissionsEntryFlg: '', // 提出物エントリシートフラグ
    submissionsResumeFlg: '', // 提出物履歴書フラグ
    submissionsResearchFlg: '', // 提出物研究概要書フラグ
    submissionsOriginalFlg: '', // 提出物オリジナル設問フラグ
    submissionsFileFlg: '', // 提出物ファイル提出フラグ
    limitDateDetermineFlag: '0', // 提出締切_種別
    determineDateTime: '', // 提出締切日指定日時
    requestDayTimeLaterDay: '', // 依頼日から○日後
    requestDayTimeToTime: '', // 依頼日から△:00まで
    anytimeReceptionEndDateTime: '', // 随時受付終了日時
    reRequestReceptFlag: '0', // 再提出受付フラグ
    senderCompanyName: '', // 送信元会社名の指定
    senderMailAddress: '', // 返信先メールアドレス
    referenceContentList: [], // 問い合わせ先リスト
    referenceContentId: '', // 問い合わせ先選択ID
    referenceContentName: '', // 問い合わせ先選択名
    referenceContentCompanyName: '', // 問い合わせ先企業名
    referenceContentDepartmentNameAndManagerName: '', // 問い合わせ先部署名／担当者氏名
    referenceContentTelephoneNumber: '', // 問い合わせ先電話番号
    referenceContentMailAddress: '', // 問い合わせ先メールアドレス
    signatureList: [], // 署名リスト
    requestSubject: '', // 件名
    requestText: '', // 本文
    attachmentFileList: [], // 添付ファイル情報
    homePageTitle1: '', // ホームページタイトル1
    homePageUrl1: '', // ホームページURL1
    homePageTitle2: '', // ホームページタイトル2
    homePageUrl2: '', // ホームページURL2
    homePageTitle3: '', // ホームページタイトル3
    homePageUrl3: '', // ホームページURL3
    thanksMessageSetFlag: '1', // サンクスメッセージ設定フラグ
    thanksMessageTemplateList: [], // サンクスメールテンプレート内容リスト
    thanksMessageTemplateId: '', // サンクスメールテンプレートID
    thanksMessageTemplateName: '', // サンクスメールテンプレート名
    thanksSubject: '', // （サンクス）件名
    thanksText: '', // （サンクス）本文
    privacyPolicyContentList: [], // 個人情報の取り扱い内容リスト
    personalDataHandlingId: '', // 個人情報の取り扱い選択ID
    personalDataHandlingName: '', // 個人情報の取り扱い名
    sysVersionNumber: '', // sysバージョン番号
    exampleLink: '', // 文例リンク
    submissionsLink: '', // 提出物内容の作成・確認はこちらリンク
    referenceLink: '', // 問い合わせ先の作成・確認はこちらリンク
    requestExampleLink: '', // 提出リクエストメールの文例集を見るリンク
    thanksMailTemplateLink: '', // サンクスメッセージテンプレートの作成・確認はこちらリンク
    thanksMailExampleLink: '', // サンクスメッセージの文例集を見るリンク
    personalDataHandlingLink: '', // 個人情報の取り扱いの作成・確認はこちらリンク
    authToken: '', // MCB認証トークン
    annual: '', // 年度
    annualList: '', // 対象年度リスト
    annualCs: '', // チェックサム
    forbiddenWordsList: [], // 禁止語リスト
    autoSubmissionRequestUseStartTime: [], // 自動提出リクエスト不可期間
    isManagementOffice: false, // 運営事務局判定
    sendReplyImpossibleTime: [], // MCBメッセージ送信不可期間
}
