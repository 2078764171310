import React, { useRef } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { FieldProps } from 'formik'
import { getYear, getMonth } from 'date-fns'
import { ja } from 'date-fns/locale'
import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { dateTimeToString, stringToDateTime } from 'common/generalUtil'
import 'react-datepicker/dist/react-datepicker.css'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    '& .react-datepicker':{
      width: '238px',
    },
    '& .react-datepicker-wrapper': {
      flex: '1 0 0',
      marginRight: '3px',
    }
  },
  input: {
    width: '150px',
    height: '32px',
    padding: '0px 20px 0px 8px',
    background: 'none #fff',
    border: '1px solid #8593a6',
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    lineHeight: '30px',
    '&:hover': {
      borderColor: '#c1d1eb !important',
    },
    '&:focus': {
      borderColor: '#3f71bd !important',
    },
    '&:disabled': {
      cursor: 'auto !important',
      border: '1px solid lightgrey !important',
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
      pointerEvents: 'none',
    },
  },
  errorBorder: {
    border: '1px solid red !important',
    '&:hover': {
      border: '1px solid red !important',
    },
    '&:focus': {
      border: '2px solid red !important',
    },
  },
  disabled: {
    backgroundImage: 'url(/css/img/common/input_calender_disable.png) !important',
    cursor: 'auto !important',
    pointerEvents: 'none',
  },
  helperText: {
    color: 'red !important',
  },
})


interface Props extends FieldProps, ReactDatePickerProps {
  className?: string
  changeState: Function
  disableFlag?: boolean,
}

const DatePickerCustom = ({
  className,
  form,
  disableFlag = false,
  field,
  changeState,
  onChange,
  ...otherProps
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form
  const inputRef = useRef<DatePicker>(null)

  const handleChange = (date: Date | null) => {
    var a = dateTimeToString(date)
    form.setFieldValue(name, a)
    form.setFieldTouched(name, true, true)
  }

  const handleIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.setFocus()
    }
  }

  let optionProps = {}

  if (otherProps.selectsStart) {
    optionProps = {
      startDate: form.values.startDate,
      endDate: form.values.endDate,
    }
  }

  if (otherProps.selectsEnd) {
    optionProps = {
      startDate: form.values.startDate,
      endDate: form.values.endDate,
      minDate: form.values.startDate,
    }
  }

  return (
    <FormControl>
      <div className={`${classes.root} ${className}`}>
        <DatePicker
          name={name}
          className={errors[name] && touched[name]
              ? `${classes.input} ${classes.errorBorder}`
              : classes.input
          }
          showTimeSelect
          timeFormat='HH:00'
          timeIntervals={60}
          dateFormat='yyyy/MM/dd HH:00'
          locale={ja}
          disabled={disableFlag}
          ref={inputRef}
          placeholderText='YYYY/MM/DD HH:00'
          selected={isNaN(Date.parse(value)) ? null : stringToDateTime(value)}       
          onChange={handleChange}
          timeCaption="時間"
          renderCustomHeader={({ 
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div>
              <button 
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                onClick={decreaseMonth} 
                disabled={prevMonthButtonDisabled}>
              </button>
              <div className="react-datepicker__current-month">
                {`${getYear(date)}年${getMonth(date) + 1}月`}
              </div>
              <button 
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--next"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}>
              </button>
            </div>
          )}
          {...otherProps}
          {...optionProps}
        />
        <button
          type='button'
          className={`${'btn only-icon icon-input_calender'} 
            ${disableFlag ? classes.disabled : ''}`}
          onClick={handleIconClick}
        />
      </div>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default DatePickerCustom