import { AutoSubmissionRequestUseStartTimeDto } from "pages/MCBHS310/formConfig"
import { MCBHS320SendReplyImpossibleTimeDto } from "pages/MCBHS320/formConfig"

export interface MCBHS340ReturnDisplayOutDto {
  returnRequestBody: string // プレビュー本文;
  returnThanksBody: string // プレビューサンクス本文;
  senderMailAddressFix: string //送信元メールアドレス固定
  sendReplyImpossibleTime: MCBHS320SendReplyImpossibleTimeDto[] // MCBメッセージ送信不可期間
}
export const initConfirmDisplayValues: MCBHS340ReturnDisplayOutDto = {
  returnRequestBody: '', // プレビュー本文;
  returnThanksBody: '', // プレビューサンクス本文;
  senderMailAddressFix: '', //送信元メールアドレス固定
  sendReplyImpossibleTime: [], // MCBメッセージ送信不可期間
}

export interface ConfirmData {
  autoSubmissionRequestSettingId: string;
  autoSubmissionRequestName: string;
  targetYear: string;
  targetCriteria: string
  useSetting: number;
  useSettingStartDate: string;
  useSettingEndDate: string;
  templateId: string;
  templateName: string;
  submissionsSelectId: string;
  submissionsName: string;
  submissionsNameStudent: string;
  submissionsNames: string;
  submissionsEntryFlg: string;
  submissionsResumeFlg: string;
  submissionsResearchFlg: string;
  submissionsOriginalFlg: string;
  submissionsFileFlg: string;
  limitDateDetermineFlag: number;
  determineDateTime: string;
  requestDayTimeLaterDay: number;
  requestDayTimeToTime: number;
  anytimeReceptionEndDateTime: string;
  reRequestReceptFlag: number;
  senderCompanyName: string;
  senderMailAddress: string;
  studentReferenceId: string;
  studentReferenceName: string;
  studentReferenceCompanyName: string;
  studentReferenceDepartmentNameAndManagerName: string;
  studentReferenceTelephoneNumber: string;
  studentReferenceMailAddress: string;
  requestSubject: string;
  requestText: string;
  requestAttachmentId: string[];
  requestAttachmentName: string[];
  homePageTitle1: string;
  homePageUrl1: string;
  homePageTitle2: string;
  homePageUrl2: string;
  homePageTitle3: string;
  homePageUrl3: string;
  thanksMessageSetFlag: number;
  thanksMessageTemplateId: string;
  thanksMessageTemplateName: string;
  thanksSubject: string;
  thanksText: string;
  personalDataHandlingId: string;
  personalDataHandlingName: string;
  forbiddenWordsList: string[];
  autoSubmissionRequestUseStartTime: AutoSubmissionRequestUseStartTimeDto[] // 自動提出リクエスト不可期間
  isManagementOffice: boolean // 運営事務局判定
  sysVersionNumber: string
}

export const initialConfirmDataValues: ConfirmData = {
  autoSubmissionRequestSettingId: '',
  autoSubmissionRequestName: '',
  targetYear: '',
  targetCriteria: '',
  useSetting: 1,
  useSettingStartDate: '',
  useSettingEndDate: '',
  templateId: '',
  templateName: '',
  submissionsSelectId: '',
  submissionsName: '',
  submissionsNameStudent: '',
  submissionsNames: '',
  submissionsEntryFlg: '',
  submissionsResumeFlg: '',
  submissionsResearchFlg: '',
  submissionsOriginalFlg: '',
  submissionsFileFlg: '',
  limitDateDetermineFlag: 0,
  determineDateTime: '',
  requestDayTimeLaterDay: 1,
  requestDayTimeToTime: 0,
  anytimeReceptionEndDateTime: '',
  reRequestReceptFlag: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  studentReferenceId: '',
  studentReferenceName: '',
  studentReferenceCompanyName: '',
  studentReferenceDepartmentNameAndManagerName: '',
  studentReferenceTelephoneNumber: '',
  studentReferenceMailAddress: '',
  requestSubject: '',
  requestText: '',
  requestAttachmentId: [],
  requestAttachmentName: [],
  homePageTitle1: '',
  homePageUrl1: '',
  homePageTitle2: '',
  homePageUrl2: '',
  homePageTitle3: '',
  homePageUrl3: '',
  thanksMessageSetFlag: 1,
  thanksMessageTemplateId: '',
  thanksMessageTemplateName: '',
  thanksSubject: '',
  thanksText: '',
  personalDataHandlingId: '',
  personalDataHandlingName: '',
  forbiddenWordsList: [],
  autoSubmissionRequestUseStartTime: [],
  isManagementOffice: false,
  sysVersionNumber: ''
}