import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialMCBHS300AutoSubReqResultInfo } from 'pages/MCBHS300/formConfig'
import {
  MCBHS310ReturnDisplayOutDto, initReturnDisplayValues, MCBHS310LinkResultDto, MCBHS310PrivacyPolicyContentInfo,
  MCBHS310ReferenceContentInfo, MCBHS310SubmissionsContentInfo,
  AttachmentFileInitialValues,
  MCBHS310EntryBoxInfo,
  MCBHS310SeminarInfo,
} from 'pages/MCBHS310/formConfig'
import { ConfirmData, initConfirmDisplayValues, initialConfirmDataValues, MCBHS340ReturnDisplayOutDto } from 'pages/MCBHS340/formConfig'
import { initFinishDisplayValues, MCBHS350ReturnDisplayOutDto } from 'pages/MCBHS350/formConfig'
import { MCBHS300EditAutoSubmissionRequest } from 'types/MCBHS300/MCBHS300EditAutoSubmissionRequest'
import { MCBHS300InitDisplayRequest } from 'types/MCBHS300/MCBHS300InitDisplayRequest'
import { MCBHS310SubmissionRequestTemplateInputRequest } from 'types/MCBHS310/MCBHS310SubmissionRequestTemplateInputRequest'
import { MCBHS310SubmitRequestPreviewRequest } from 'types/MCBHS310/MCBHS310SubmitRequestPreviewRequest'
import { MCBHS340AutoSubmissionRequestTemplateRequest } from 'types/MCBHS340/MCBHS340AutoSubmissionRequestTemplateRequest'
import { MCBHS350NewRequest } from 'types/MCBHS350/MCBHS350NewRequest'
import { MCBHS350OverwriteRequest } from 'types/MCBHS350/MCBHS350OverwriteRequest'
import { isEmpty, now, getNdaysLater } from 'common/generalUtil'
import moment from 'moment'
import { initMCBHS320DisplayValues, MCBHS320ReturnDisplayOutDto, MCBHS320SendReplyImpossibleTimeDto } from 'pages/MCBHS320/formConfig'
import { MCBHS320DeleteAutoSubmissionRequest } from 'types/MCBHS320/MCBHS320DeleteAutoSubmissionRequest'
import { MCBHS320SubmissionRequestTemplateInputRequest } from 'types/MCBHS320/MCBHS320SubmissionRequestTemplateInputRequest'

export interface EntryBox {
  entryBoxId: number //エントリーボックスID
  entryBoxName: string //エントリーボックス名
}

export interface Seminar {
  seminarId: number //セミナーID
  seminarName: string //セミナー名
}
export interface AutoSubReqResultDto {
  autoSubmissionRequestSettingId: number //自動提出リクエスト設定ID
  sequence: number // 連番
  templateName: string //自動提出リクエスト登録名
  useSetting: string //利用設定
  useSettingStartDate: Date //利用設定_利用開始日時
  useSettingEndDate: Date //利用設定_利用終了日時
  submissionContentId: number //提出物内容ID
  submissionContentName: string //提出物内容名
  // 27P #10655 start
  submissionContentDeleteFlg: boolean // 提出物内容削除フラグ
  // 27P #10655 end
  targetCriteria: string //対象者設定
  targetCriteriaParsingFlag: boolean //対象者設定解析フラグ
  updateTime: Date //更新日時
  updaterFullName: string //更新者
  displayOrder: number //表示順
  sysVersionNumber: number //sysバージョン番号
  entryBoxList: EntryBox[] //エントリーボックス
  seminarList: Seminar[] //セミナー
  operatingStatus: number // 稼働状況
  operatingStatusLine1: string // 稼働状況１行目
  operatingStatusLine2: string // 稼働状況２行目
  operatingStatusLine3: string // 稼働状況３行目
}
export interface MCBHS300AutoSubReqResultInfo {
  searchCondition: MCBHS300InitDisplayRequest //入力する検索条件
  recruitmentManagementDivision: string //採用管理区分
  targetYear: string //対象年度
  message: string //メッセージ
  maxAutoSubReqTemplateCount: string //件数上限
  autoSubReqResultList: AutoSubReqResultDto[] //検索結果
  // 27P #10663 start
  loadingFlag: boolean
  // 27P #10663 end
}
interface AutoSubmissionRequest {
  mCBHS300AutoSubReqResultInfo: MCBHS300AutoSubReqResultInfo//自動提出リクエスト一覧
  autoSubReqPage: number
  autoSubmReqInsInfo: MCBHS310ReturnDisplayOutDto
  autoSubmReqUpdInfo: MCBHS320ReturnDisplayOutDto
  confirmData: ConfirmData
  mCBHS340ReturnDisplayOutDto: MCBHS340ReturnDisplayOutDto
  mCBHS350ReturnDisplayOutDto: MCBHS350ReturnDisplayOutDto
  createDialogOpen: boolean
  updateDialogOpen: boolean
  confirmDialogOpen: boolean
  finishDialogOpen: boolean
  openModalDataArgs: openModalDataArgs,
  returnBody: string
}
export interface openModalDataArgs {
  openCheckModal: boolean,
  modalMessageArgs: modalMessageArgs
}
interface modalMessageArgs {
  messageId: string,
  args: string[]
}

const initialopenModalArgs = {
  openCheckModal: false,
  modalMessageArgs: {
    messageId: "",
    args: []
  }
}
const initialState: AutoSubmissionRequest = {
  mCBHS300AutoSubReqResultInfo: initialMCBHS300AutoSubReqResultInfo,
  autoSubReqPage: 0,
  autoSubmReqInsInfo: initReturnDisplayValues,
  autoSubmReqUpdInfo: initMCBHS320DisplayValues,
  confirmData: initialConfirmDataValues,
  mCBHS340ReturnDisplayOutDto: initConfirmDisplayValues,
  mCBHS350ReturnDisplayOutDto: initFinishDisplayValues,
  createDialogOpen: false,
  updateDialogOpen: false,
  confirmDialogOpen: false,
  finishDialogOpen: false,
  openModalDataArgs: initialopenModalArgs,
  returnBody: '',
}

const autoSubmissionRequestSlice = createSlice({
  name: 'autoSubmissionRequest',
  initialState,
  reducers: {
    // MCBHS300_自動提出リクエスト設定一覧 START
    selectAutoSubmissionRequestList(state, action: PayloadAction<MCBHS300InitDisplayRequest>) {
      state.mCBHS300AutoSubReqResultInfo = initialMCBHS300AutoSubReqResultInfo
      return state
    },
    setAutoSubmissionRequestResultInfo(state, action: PayloadAction<MCBHS300AutoSubReqResultInfo>) {
      state.mCBHS300AutoSubReqResultInfo = action.payload
      const datas: AutoSubReqResultDto[] = state.mCBHS300AutoSubReqResultInfo.autoSubReqResultList
      // 27P #10732 start
      const targetYear = state.mCBHS300AutoSubReqResultInfo.targetYear.substring(state.mCBHS300AutoSubReqResultInfo.targetYear.length - 4)
      const targetYearDate = stringToDate(targetYear + '/03/31')
      // 27P #10732 end
      if (datas) {
        datas.map(i => {
          // 自動提出リクエスト名
          if (i.templateName.length > 50) {
            i.templateName = i.templateName.substr(0, 50) + '...'
          }

          // 稼働状況
          // 自動提出リクエスト一覧情報．利用設定が"0"の場合、"終了"で表示する。(2～3行目は非表示)
          if (i.useSetting === '0') {
            i.operatingStatus = 3
            i.operatingStatusLine1 = '終了'
            i.operatingStatusLine2 = ''
            i.operatingStatusLine3 = ''
          } else {
            const currentDateTime = now()
            const nowDate = stringToDate(dateToString(currentDateTime))
            const useSettingStartDate = stringToDate(dateToString(i.useSettingStartDate))
            const useSettingEndDate = stringToDate(dateToString(i.useSettingEndDate))

            i.operatingStatusLine2 = '利用期間：'
            i.operatingStatusLine3 = dateToString(i.useSettingStartDate) + '～' + dateToString(i.useSettingEndDate)
            // 稼働前
            // 稼働前：画面表示した日　＜　利用期間Fromの日（時分は関係なく日で判定）
            if (nowDate < useSettingStartDate) {
              i.operatingStatus = 1
              i.operatingStatusLine1 = '稼働前'
              // 稼働中：利用期間Fromの日（時分は関係なく日で判定）　≦　画面表示した日
              // 画面表示した日　≦　利用期間Toの日（時分は関係なく日で判定）
            } else if (useSettingStartDate <= nowDate && nowDate <= useSettingEndDate) {
              i.operatingStatus = 2
              i.operatingStatusLine1 = '稼働中'
              //終了：利用期間Toの日（時分は関係なく日で判定）　＜　画面表示した日時
            } else if (useSettingEndDate < nowDate) {
              i.operatingStatus = 3
              i.operatingStatusLine1 = '終了'
            }
          }
          // 27P #10655 start
          i.submissionContentDeleteFlg = false
          // 自動提出リクエスト一覧情報．提出物内容IDが空場合、"終了"で表示する。
          if (i.submissionContentId == null) {
            i.operatingStatus = 3
            i.operatingStatusLine1 = '終了'
            i.submissionContentName = '削除されました'
            i.submissionContentDeleteFlg = true
          // 27P #10655 end
          // 自動提出リクエスト設定の年度に対応する年の3月31日（例：2027年度の場合2027/03/31）が過去の日付の場合、「終了」で表示する。
          } else if (stringToDate(dateToString(now())) > targetYearDate) {
            i.operatingStatus = 3
            i.operatingStatusLine1 = '終了'
          }
          // 対象設定条件
          if (i.targetCriteriaParsingFlag) {
            i.targetCriteria = ''
            let entryBoxAndseminarNames = ''
            let entryBoxNames = ''
            let seminarNames = ''
            if (i.entryBoxList) {
              i.entryBoxList.map(id => {
                entryBoxNames = isEmpty(entryBoxNames) ? id.entryBoxName : entryBoxNames + '、' + id.entryBoxName
              })
              entryBoxNames = 'エントリーボックス：' + entryBoxNames
              entryBoxNames = addNewLinesChar(entryBoxNames, 30)
            }
            if (i.seminarList) {
              i.seminarList.map(id => {
                seminarNames = isEmpty(seminarNames) ? id.seminarName : seminarNames + '、' + id.seminarName
              })
              seminarNames = 'セミナー画面：' + seminarNames
              seminarNames = addNewLinesChar(seminarNames, 30)
            }

            if (entryBoxNames.length > 0) {
              entryBoxAndseminarNames = entryBoxAndseminarNames + entryBoxNames
            }
            if (entryBoxNames.length > 0 && seminarNames.length > 0) {
              entryBoxAndseminarNames = entryBoxAndseminarNames + '\n'
            }
            if (seminarNames.length > 0) {
              entryBoxAndseminarNames = entryBoxAndseminarNames + seminarNames
            }

            let lines = entryBoxAndseminarNames.split('\n');
            if (lines.length > 3) {
              i.targetCriteria = lines[0] + '\n' + lines[1] + '\n' + lines[2] + '...'
            } else {
              i.targetCriteria = entryBoxAndseminarNames
            }
          }
        })
      }

      return state
    },
    editAutoSubmissionRequest(state, action: PayloadAction<MCBHS300EditAutoSubmissionRequest>) {
      return state
    },
    updateAutoSubmissionRequestSort(state, action: PayloadAction<{ updateOrderList: AutoSubReqResultDto[], selectParam: MCBHS300InitDisplayRequest }>) {
      state.mCBHS300AutoSubReqResultInfo.autoSubReqResultList = action.payload.updateOrderList
      return state
    },
    checkAutoSubReqCount(state, action: PayloadAction<string>) {
      return state;
    },
    setCreateDialogOpen(state, action: PayloadAction<boolean>) {
      state.createDialogOpen = action.payload
      return state
    },
    setUpdateDialogOpen(state, action: PayloadAction<boolean>) {
      state.updateDialogOpen = action.payload
      return state
    },
    // 27P #10663 start
    setLoadingFlag(state, action: PayloadAction<boolean>) {
      state.mCBHS300AutoSubReqResultInfo.loadingFlag = action.payload
      return state
    },
    setMaxAutoSubReqTemplateCount(state, action: PayloadAction<string>) {
      state.mCBHS300AutoSubReqResultInfo.maxAutoSubReqTemplateCount = action.payload
      return state
    },
    // 27P #10663 end
    // MCBHS300_自動提出リクエスト設定一覧 END

    // MCBHS310_自動提出リクエスト登録 START
    setAutoSubmissionReqAddInit(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo = {
        ...initReturnDisplayValues
      }
      let newEntryBoxList: any = []
      newEntryBoxList = action.payload.entryBoxList.map(entryBox => {
        return {
          ...entryBox,
          selectFlag: false,
          disabledFlag: false,
        }
      });
      state.autoSubmReqInsInfo.entryBoxList = newEntryBoxList
      let newSeminarList: any = []
      newSeminarList = action.payload.seminarList.map(seminar => {
        return {
          ...seminar,
          selectFlag: false,
          disabledFlag: false,
        }
      })
      state.autoSubmReqInsInfo.seminarList = newSeminarList
      state.autoSubmReqInsInfo.submissionRequestTemplateInfoList = action.payload.submissionRequestTemplateInfoList
      state.autoSubmReqInsInfo.signatureList = action.payload.signatureList

      let newSubmissionContentList: MCBHS310SubmissionsContentInfo[] = []
      let newContactContentList: MCBHS310ReferenceContentInfo[] = []
      let newPrivacyPolicyContentList: MCBHS310PrivacyPolicyContentInfo[] = []
      action.payload.submissionsContentList.map(i => {
        if (i.hiddenFlg === false) {
          newSubmissionContentList = [...newSubmissionContentList, i]
        }
      })
      action.payload.referenceContentList.map(i => {
        if (i.hiddenFlg === false) {
          newContactContentList = [...newContactContentList, i]
        }
      })
      action.payload.privacyPolicyContentList.map(i => {
        if (i.hiddenFlg === false) {
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList, i]
        }
      })
      state.autoSubmReqInsInfo.submissionsContentList = newSubmissionContentList
      state.autoSubmReqInsInfo.referenceContentList = newContactContentList
      state.autoSubmReqInsInfo.thanksMessageTemplateList = action.payload.thanksMessageTemplateList
      state.autoSubmReqInsInfo.privacyPolicyContentList = newPrivacyPolicyContentList

      let submissionsList: number[] = []
      let thanksList: number[] = []
      let contactList: number[] = []
      let personalList: number[] = []
      state.autoSubmReqInsInfo.submissionsContentList.map(i => {
        if (i.submissionsContentId === Number(action.payload.submissionsSelectId)) {
          state.autoSubmReqInsInfo.submissionsName = i.submissionsNameForCompany
          state.autoSubmReqInsInfo.submissionsNameStudent = i.submissionsNameForStudent

          state.autoSubmReqInsInfo.submissionsEntryFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? '1' : '0') : '0'
          state.autoSubmReqInsInfo.submissionsResumeFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? '1' : '0') : '0'
          state.autoSubmReqInsInfo.submissionsResearchFlg = i.researchOverviewsSubmissionFlg === true ? '1' : '0'
          state.autoSubmReqInsInfo.submissionsOriginalFlg = i.originalQuestionSubmissionFlg === true ? '1' : '0'
          state.autoSubmReqInsInfo.submissionsFileFlg = i.fileSubmissionFlg === true ? '1' : '0'

          state.autoSubmReqInsInfo.submissionsNames = (state.autoSubmReqInsInfo.submissionsEntryFlg === '1' ? 'エントリーシート、' : '') +
            (state.autoSubmReqInsInfo.submissionsResumeFlg === '1' ? '履歴書、' : '') +
            (state.autoSubmReqInsInfo.submissionsResearchFlg === '1' ? '研究概要書、' : '') +
            (state.autoSubmReqInsInfo.submissionsOriginalFlg === '1' ? 'オリジナル設問、' : '') +
            (state.autoSubmReqInsInfo.submissionsFileFlg === '1' ? 'ファイル、' : '')
        }
        submissionsList.push(i.submissionsContentId)
      })
      state.autoSubmReqInsInfo.thanksMessageTemplateList.map(i => {
        if (i.thanksMailTemplateId === Number(action.payload.thanksMessageTemplateId)) {
          state.autoSubmReqInsInfo.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
        }
        thanksList.push(i.thanksMailTemplateId)
      })
      state.autoSubmReqInsInfo.referenceContentList.map(i => {
        if (i.inquiryId === Number(action.payload.referenceContentId)) {
          state.autoSubmReqInsInfo.referenceContentName = i.inquiryRegisteredName
          state.autoSubmReqInsInfo.referenceContentCompanyName = i.companyName
          state.autoSubmReqInsInfo.referenceContentDepartmentNameAndManagerName = i.departmentNameAndManagerName
          state.autoSubmReqInsInfo.referenceContentTelephoneNumber = i.telNumber
          state.autoSubmReqInsInfo.referenceContentMailAddress = i.emailAddress
        }
        contactList.push(i.inquiryId)
      })
      state.autoSubmReqInsInfo.privacyPolicyContentList.map(i => {
        if (i.myCareerBoxPrivacyPolicyId === Number(action.payload.personalDataHandlingId)) {
          state.autoSubmReqInsInfo.personalDataHandlingName = i.privacyPolicyRegisterdName
        }
        personalList.push(i.myCareerBoxPrivacyPolicyId)
      })

      if (!submissionsList.includes(Number(action.payload.submissionsSelectId))) {
        state.autoSubmReqInsInfo.submissionsNames = ''
        state.autoSubmReqInsInfo.submissionsNameStudent = ''
        state.autoSubmReqInsInfo.submissionsSelectId = ''
        state.autoSubmReqInsInfo.submissionsName = ''
      }

      if (!contactList.includes(Number(action.payload.referenceContentId))) {
        state.autoSubmReqInsInfo.referenceContentId = ''
        state.autoSubmReqInsInfo.referenceContentName = ''
        state.autoSubmReqInsInfo.referenceContentCompanyName = ''
        state.autoSubmReqInsInfo.referenceContentDepartmentNameAndManagerName = ''
        state.autoSubmReqInsInfo.referenceContentTelephoneNumber = ''
        state.autoSubmReqInsInfo.referenceContentMailAddress = ''
      }

      if (!thanksList.includes(Number(action.payload.thanksMessageTemplateId))) {
        state.autoSubmReqInsInfo.thanksMessageTemplateId = ''
        state.autoSubmReqInsInfo.thanksMessageTemplateName = ''
      }

      if (!personalList.includes(Number(action.payload.personalDataHandlingId))) {
        state.autoSubmReqInsInfo.personalDataHandlingId = ''
        state.autoSubmReqInsInfo.personalDataHandlingName = ''
      }

      state.autoSubmReqInsInfo.exampleLink = action.payload.exampleLink
      state.autoSubmReqInsInfo.submissionsLink = action.payload.submissionsLink
      state.autoSubmReqInsInfo.referenceLink = action.payload.referenceLink
      state.autoSubmReqInsInfo.requestExampleLink = action.payload.requestExampleLink
      state.autoSubmReqInsInfo.thanksMailTemplateLink = action.payload.thanksMailTemplateLink
      state.autoSubmReqInsInfo.thanksMailExampleLink = action.payload.thanksMailExampleLink
      state.autoSubmReqInsInfo.personalDataHandlingLink = action.payload.personalDataHandlingLink
      state.autoSubmReqInsInfo.forbiddenWordsList = action.payload.forbiddenWordsList
      state.autoSubmReqInsInfo.authToken = action.payload.authToken
      state.autoSubmReqInsInfo.annual = action.payload.annual
      state.autoSubmReqInsInfo.annualList = action.payload.annualList
      state.autoSubmReqInsInfo.annualCs = action.payload.annualCs

      state.autoSubmReqInsInfo.autoSubmissionRequestUseStartTime = action.payload.autoSubmissionRequestUseStartTime
      state.autoSubmReqInsInfo.isManagementOffice = action.payload.isManagementOffice
      return state
    },
    clearTemplateSettingId(state) {
      state.autoSubmReqInsInfo.submissionRequestTemplateId = '0'
      return state
    },
    selectionSubReqTemplate(state, action: PayloadAction<{ submissionRequestTemplateSettingId: string, values: MCBHS310ReturnDisplayOutDto }>) {
      const submissionRequestTemplateSettingId = action.payload.submissionRequestTemplateSettingId
      state.autoSubmReqInsInfo = {
        ...action.payload.values,
        submissionRequestTemplateId: submissionRequestTemplateSettingId
      }
      if (submissionRequestTemplateSettingId !== '-1') {
        state.autoSubmReqInsInfo.submissionRequestTemplateInfoList.map(template => {
          if (template && template.submissionRequestTemplateSettingId === submissionRequestTemplateSettingId) {

            let submissionsList: number[] = []
            state.autoSubmReqInsInfo.submissionsContentList.map(i => {
              if (i.submissionsContentId === Number(template.submissionContentId)) {
                state.autoSubmReqInsInfo.submissionsSelectId = String(i.submissionsContentId)
                state.autoSubmReqInsInfo.submissionsName = i.submissionsNameForCompany
                state.autoSubmReqInsInfo.submissionsNameStudent = i.submissionsNameForStudent
                state.autoSubmReqInsInfo.submissionsEntryFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? '1' : '0') : '0'
                state.autoSubmReqInsInfo.submissionsResumeFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? '1' : '0') : '0'
                state.autoSubmReqInsInfo.submissionsResearchFlg = i.researchOverviewsSubmissionFlg === true ? '1' : '0'
                state.autoSubmReqInsInfo.submissionsOriginalFlg = i.originalQuestionSubmissionFlg === true ? '1' : '0'
                state.autoSubmReqInsInfo.submissionsFileFlg = i.fileSubmissionFlg === true ? '1' : '0'

                state.autoSubmReqInsInfo.submissionsNames =
                  (state.autoSubmReqInsInfo.submissionsEntryFlg === '1' ? 'エントリーシート、' : '') +
                  (state.autoSubmReqInsInfo.submissionsResumeFlg === '1' ? '履歴書、' : '') +
                  (state.autoSubmReqInsInfo.submissionsResearchFlg === '1' ? '研究概要書、' : '') +
                  (state.autoSubmReqInsInfo.submissionsOriginalFlg === '1' ? 'オリジナル設問、' : '') +
                  (state.autoSubmReqInsInfo.submissionsFileFlg === '1' ? 'ファイル、' : '')
              }
              submissionsList.push(i.submissionsContentId)
            })
            if (!submissionsList.includes(Number(template.submissionContentId))) {
              state.autoSubmReqInsInfo.submissionsSelectId = ''
              state.autoSubmReqInsInfo.submissionsName = ''
              state.autoSubmReqInsInfo.submissionsNameStudent = ''
              state.autoSubmReqInsInfo.submissionsNames = ''
              state.autoSubmReqInsInfo.submissionsEntryFlg = ''
              state.autoSubmReqInsInfo.submissionsResumeFlg = ''
              state.autoSubmReqInsInfo.submissionsResearchFlg = ''
              state.autoSubmReqInsInfo.submissionsOriginalFlg = ''
              state.autoSubmReqInsInfo.submissionsFileFlg = ''
            }

            state.autoSubmReqInsInfo.limitDateDetermineFlag = template.submissionDeadlineKind
            if (!isEmpty(template.submissionDeadlineKind) && !isEmpty(template.submissionDeadlineDay) && !isEmpty(template.submissionDeadlineHour)) {
              if (template.submissionDeadlineKind === '0') {
                let determineDateTime = template.submissionDeadlineDay + template.submissionDeadlineHour.toString() + '00';
                let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
                let formatedDate = determineDateTime.replace(pattern, '$1/$2/$3 $4:$5');
                state.autoSubmReqInsInfo.determineDateTime = formatedDate;
                state.autoSubmReqInsInfo.requestDayTimeLaterDay = ''
                state.autoSubmReqInsInfo.requestDayTimeToTime = ''
                state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = ''
              } else if (template.submissionDeadlineKind === '2') {
                let anytimeReceptionEndDateTime = template.submissionDeadlineDay + template.submissionDeadlineHour.toString() + '00';
                let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
                let formatedDate = anytimeReceptionEndDateTime.replace(pattern, '$1/$2/$3 $4:$5');
                state.autoSubmReqInsInfo.determineDateTime = ''
                state.autoSubmReqInsInfo.requestDayTimeLaterDay = ''
                state.autoSubmReqInsInfo.requestDayTimeToTime = ''
                state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = formatedDate;
              } else {
                state.autoSubmReqInsInfo.determineDateTime = ''
                state.autoSubmReqInsInfo.requestDayTimeLaterDay = template.submissionDeadlineDay
                state.autoSubmReqInsInfo.requestDayTimeToTime = template.submissionDeadlineHour
                state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = ''
              }
            } else {
              state.autoSubmReqInsInfo.limitDateDetermineFlag = '0'
              state.autoSubmReqInsInfo.determineDateTime = ''
              state.autoSubmReqInsInfo.requestDayTimeLaterDay = ''
              state.autoSubmReqInsInfo.requestDayTimeToTime = ''
              state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = ''
            }

            state.autoSubmReqInsInfo.reRequestReceptFlag = template.resubmitReceptionFlag
            state.autoSubmReqInsInfo.senderCompanyName = template.senderCompanyName
            state.autoSubmReqInsInfo.senderMailAddress = template.senderMailAddress

            let contactList: number[] = []
            state.autoSubmReqInsInfo.referenceContentList.map(i => {
              if (i.inquiryId === Number(template.contactId)) {
                state.autoSubmReqInsInfo.referenceContentId = String(i.inquiryId)
                state.autoSubmReqInsInfo.referenceContentName = i.inquiryRegisteredName
                state.autoSubmReqInsInfo.referenceContentCompanyName = i.companyName
                state.autoSubmReqInsInfo.referenceContentDepartmentNameAndManagerName = i.departmentNameAndManagerName
                state.autoSubmReqInsInfo.referenceContentTelephoneNumber = i.telNumber
                state.autoSubmReqInsInfo.referenceContentMailAddress = i.emailAddress
              }
              contactList.push(i.inquiryId)
            })
            if (!contactList.includes(Number(template.contactId))) {
              state.autoSubmReqInsInfo.referenceContentId = ''
              state.autoSubmReqInsInfo.referenceContentName = ''
              state.autoSubmReqInsInfo.referenceContentCompanyName = ''
              state.autoSubmReqInsInfo.referenceContentDepartmentNameAndManagerName = ''
              state.autoSubmReqInsInfo.referenceContentTelephoneNumber = ''
              state.autoSubmReqInsInfo.referenceContentMailAddress = ''
            }

            state.autoSubmReqInsInfo.requestSubject = template.subject
            state.autoSubmReqInsInfo.requestText = template.body
            if (template.attachmentFileList && template.attachmentFileList.length > 0) {
              state.autoSubmReqInsInfo.attachmentFileList = template.attachmentFileList
            } else {
              state.autoSubmReqInsInfo.attachmentFileList = AttachmentFileInitialValues
            }
            state.autoSubmReqInsInfo.homePageTitle1 = template.homePageTitle1 ? template.homePageTitle1 : ''
            state.autoSubmReqInsInfo.homePageUrl1 = template.homePageUrl1 ? template.homePageUrl1 : ''
            state.autoSubmReqInsInfo.homePageTitle2 = template.homePageTitle2 ? template.homePageTitle2 : ''
            state.autoSubmReqInsInfo.homePageUrl2 = template.homePageUrl2 ? template.homePageUrl2 : ''
            state.autoSubmReqInsInfo.homePageTitle3 = template.homePageTitle3 ? template.homePageTitle3 : ''
            state.autoSubmReqInsInfo.homePageUrl3 = template.homePageUrl3 ? template.homePageUrl3 : ''

            state.autoSubmReqInsInfo.thanksMessageSetFlag = template.thanksMessageSettingFlag
            if (template.thanksMessageSettingFlag === '1') {
              state.autoSubmReqInsInfo.thanksSubject = template.thanksSubject
              state.autoSubmReqInsInfo.thanksText = template.thanksBody
              if (isEmpty(template.thanksMessageTemplateId)) {
                state.autoSubmReqInsInfo.thanksMessageTemplateId = ''
                state.autoSubmReqInsInfo.thanksMessageTemplateName = ''
              } else {
                let thanksList: number[] = []
                state.autoSubmReqInsInfo.thanksMessageTemplateList.map(i => {
                  if (i.thanksMailTemplateId === Number(template.thanksMessageTemplateId)) {
                    state.autoSubmReqInsInfo.thanksMessageTemplateId = String(i.thanksMailTemplateId)
                    state.autoSubmReqInsInfo.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
                  }
                  thanksList.push(i.thanksMailTemplateId)
                })
                if (!thanksList.includes(Number(template.thanksMessageTemplateId))) {
                  state.autoSubmReqInsInfo.thanksMessageTemplateId = ''
                  state.autoSubmReqInsInfo.thanksMessageTemplateName = ''
                }
              }
            } else {
              state.autoSubmReqInsInfo.thanksMessageTemplateId = ''
              state.autoSubmReqInsInfo.thanksMessageTemplateName = ''
              state.autoSubmReqInsInfo.thanksSubject = ''
              state.autoSubmReqInsInfo.thanksText = ''
            }

            let personalList: number[] = []
            state.autoSubmReqInsInfo.privacyPolicyContentList.map(i => {
              if (i.myCareerBoxPrivacyPolicyId === Number(template.privacyPolicyId)) {
                state.autoSubmReqInsInfo.personalDataHandlingId = String(i.myCareerBoxPrivacyPolicyId)
                state.autoSubmReqInsInfo.personalDataHandlingName = i.privacyPolicyRegisterdName
              }
              personalList.push(i.myCareerBoxPrivacyPolicyId)
            })
            if (!personalList.includes(Number(template.privacyPolicyId))) {
              state.autoSubmReqInsInfo.personalDataHandlingId = ''
              state.autoSubmReqInsInfo.personalDataHandlingName = ''
            }
          }
        })
      } else {
        state.autoSubmReqInsInfo.submissionsSelectId = ''
        state.autoSubmReqInsInfo.submissionsName = ''
        state.autoSubmReqInsInfo.submissionsNameStudent = ''
        state.autoSubmReqInsInfo.submissionsNames = ''
        state.autoSubmReqInsInfo.submissionsEntryFlg = ''
        state.autoSubmReqInsInfo.submissionsResumeFlg = ''
        state.autoSubmReqInsInfo.submissionsResearchFlg = ''
        state.autoSubmReqInsInfo.submissionsOriginalFlg = ''
        state.autoSubmReqInsInfo.submissionsFileFlg = ''
        state.autoSubmReqInsInfo.limitDateDetermineFlag = '0'
        state.autoSubmReqInsInfo.determineDateTime = ''
        state.autoSubmReqInsInfo.requestDayTimeLaterDay = ''
        state.autoSubmReqInsInfo.requestDayTimeToTime = ''
        state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = ''
        state.autoSubmReqInsInfo.reRequestReceptFlag = '0'
        state.autoSubmReqInsInfo.senderCompanyName = ''
        state.autoSubmReqInsInfo.senderMailAddress = ''
        state.autoSubmReqInsInfo.referenceContentId = ''
        state.autoSubmReqInsInfo.referenceContentName = ''
        state.autoSubmReqInsInfo.referenceContentCompanyName = ''
        state.autoSubmReqInsInfo.referenceContentDepartmentNameAndManagerName = ''
        state.autoSubmReqInsInfo.referenceContentTelephoneNumber = ''
        state.autoSubmReqInsInfo.referenceContentMailAddress = ''
        state.autoSubmReqInsInfo.requestSubject = ''
        state.autoSubmReqInsInfo.requestText = ''
        state.autoSubmReqInsInfo.attachmentFileList = AttachmentFileInitialValues
        state.autoSubmReqInsInfo.homePageTitle1 = ''
        state.autoSubmReqInsInfo.homePageUrl1 = ''
        state.autoSubmReqInsInfo.homePageTitle2 = ''
        state.autoSubmReqInsInfo.homePageUrl2 = ''
        state.autoSubmReqInsInfo.homePageTitle3 = ''
        state.autoSubmReqInsInfo.homePageUrl3 = ''
        state.autoSubmReqInsInfo.thanksMessageSetFlag = '1'
        state.autoSubmReqInsInfo.thanksMessageTemplateId = ''
        state.autoSubmReqInsInfo.thanksMessageTemplateName = ''
        state.autoSubmReqInsInfo.thanksSubject = ''
        state.autoSubmReqInsInfo.thanksText = ''
        state.autoSubmReqInsInfo.personalDataHandlingId = ''
        state.autoSubmReqInsInfo.personalDataHandlingName = ''
      }
      return state
    },
    changeUseSetting(state, action: PayloadAction<{ values: MCBHS310ReturnDisplayOutDto, value: string }>) {
      const value = action.payload.value
      state.autoSubmReqInsInfo = JSON.parse(JSON.stringify(action.payload.values))
      state.autoSubmReqInsInfo.useSetting = value
      if (value === '0') {
        state.autoSubmReqInsInfo.useSettingStartDate = ''
        state.autoSubmReqInsInfo.useSettingEndDate = ''
      }
      return state
    },
    changeDeadlineValue(state, action: PayloadAction<{ values: MCBHS310ReturnDisplayOutDto, value: string }>) {
      const value = action.payload.value
      state.autoSubmReqInsInfo = JSON.parse(JSON.stringify(action.payload.values))
      state.autoSubmReqInsInfo.limitDateDetermineFlag = value
      if (value === '0') {
        state.autoSubmReqInsInfo.requestDayTimeLaterDay = ''
        state.autoSubmReqInsInfo.requestDayTimeToTime = ''
        state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = ''
      } else if (value === '1') {
        state.autoSubmReqInsInfo.determineDateTime = ''
        state.autoSubmReqInsInfo.anytimeReceptionEndDateTime = ''
      } else {
        state.autoSubmReqInsInfo.determineDateTime = ''
        state.autoSubmReqInsInfo.requestDayTimeLaterDay = ''
        state.autoSubmReqInsInfo.requestDayTimeToTime = ''
        state.autoSubmReqInsInfo.reRequestReceptFlag = '0'
      }
      return state
    },
    openApi(state, action: PayloadAction<MCBHS310LinkResultDto>) {
      return state
    },
    getReloadSubmissions(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo = action.payload
      return state
    },
    setReloadSubmissions(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo.submissionsSelectId = ''
      state.autoSubmReqInsInfo.submissionsNameStudent = ''
      state.autoSubmReqInsInfo.submissionsName = ''
      state.autoSubmReqInsInfo.submissionsNames = ''
      let newSubmissionContentList: MCBHS310SubmissionsContentInfo[] = []
      action.payload.submissionsContentList.map(i => {
        if (i.hiddenFlg === false) {
          newSubmissionContentList = [...newSubmissionContentList, i]
        }
      })
      state.autoSubmReqInsInfo.submissionsContentList = newSubmissionContentList
      return state
    },
    getReloadThanksTemplate(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo = action.payload
      return state
    },
    setReloadThanksTemplate(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo.thanksMessageTemplateList = action.payload.thanksMessageTemplateList
      state.autoSubmReqInsInfo.thanksMessageTemplateId = ''
      state.autoSubmReqInsInfo.thanksMessageTemplateName = ''
      return state
    },
    getReloadReference(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo = action.payload
      return state
    },
    setReloadReference(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo.referenceContentId = ''
      state.autoSubmReqInsInfo.referenceContentName = ''
      state.autoSubmReqInsInfo.referenceContentCompanyName = ''
      state.autoSubmReqInsInfo.referenceContentDepartmentNameAndManagerName = ''
      state.autoSubmReqInsInfo.referenceContentTelephoneNumber = ''
      state.autoSubmReqInsInfo.referenceContentMailAddress = ''
      let newReferenceSelectionList: MCBHS310ReferenceContentInfo[] = []
      action.payload.referenceContentList.map(i => {
        if (i.hiddenFlg === false) {
          newReferenceSelectionList = [...newReferenceSelectionList, i]
        }
      })
      state.autoSubmReqInsInfo.referenceContentList = newReferenceSelectionList
      return state
    },
    getReloadPersonal(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo = action.payload
      return state
    },
    setReloadPersonal(state, action: PayloadAction<MCBHS310ReturnDisplayOutDto>) {
      state.autoSubmReqInsInfo.personalDataHandlingId = ''
      state.autoSubmReqInsInfo.personalDataHandlingName = ''
      let newPrivacyPolicyContentList: MCBHS310PrivacyPolicyContentInfo[] = []
      action.payload.privacyPolicyContentList.map(i => {
        if (i.hiddenFlg === false) {
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList, i]
        }
      })
      state.autoSubmReqInsInfo.privacyPolicyContentList = newPrivacyPolicyContentList
      return state
    },
    getPreview(state, action: PayloadAction<{ formData: MCBHS310SubmitRequestPreviewRequest, setHasDisplayPreview: (value: boolean) => void }>) {
      return state
    },
    setPreview(state, action: PayloadAction<string>) {
      state.returnBody = action.payload
      return state
    },
    deleteHomePageValue(state, action: PayloadAction<{ values: MCBHS310ReturnDisplayOutDto, number: number }>) {
      state.autoSubmReqInsInfo = action.payload.values
      if (action.payload.number == 1) {
        state.autoSubmReqInsInfo.homePageTitle1 = ''
        state.autoSubmReqInsInfo.homePageUrl1 = ''
      } else if (action.payload.number == 2) {
        state.autoSubmReqInsInfo.homePageTitle2 = ''
        state.autoSubmReqInsInfo.homePageUrl2 = ''
      } else if (action.payload.number == 3) {
        state.autoSubmReqInsInfo.homePageTitle3 = ''
        state.autoSubmReqInsInfo.homePageUrl3 = ''
      }
      return state
    },
    autoSubmissionRequestConfirm(state, action: PayloadAction<{
      requestData: MCBHS310SubmissionRequestTemplateInputRequest,
      confirmData: ConfirmData,
      files: File[],
      entryBoxInfoList: MCBHS310EntryBoxInfo[],
      seminarInfoList: MCBHS310SeminarInfo[],
      executeFlag: string
    }>) {
      return state
    },
    openModalWithArgMessage(state, action: PayloadAction<{ messageId: string, args: string[] }>) {
      state.openModalDataArgs.modalMessageArgs.messageId = action.payload.messageId
      state.openModalDataArgs.modalMessageArgs.args = action.payload.args
      state.openModalDataArgs.openCheckModal = true
    },
    closeModelArgs(state) {
      state.openModalDataArgs = initialopenModalArgs
      return state;
    },
    // MCBHS310_自動提出リクエスト登録 END

    // MCBHS320_自動提出リクエスト編集 START
    setAutoSubmissionReqUpdInit(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo = action.payload
      state.autoSubmReqUpdInfo.destinationSelection = '3'

      let entryBoxIds = action.payload.entryBoxIds
      let seminarIds = action.payload.seminarIds
      let systemDate = now()
      let newEntryBoxList: any = []
      newEntryBoxList = action.payload.entryBoxList.map(entryBox => {
        let displayEndDate = entryBox.displayEndDate ? stringToDate(entryBox.displayEndDate) : null
        // 選択済みの「エントリーボックス」「セミナー」情報の表示終了日が過去日の場合、非活性かつ未選択の状態になる
        let disabledFlag = displayEndDate && displayEndDate < systemDate ? true : false
        return {
          ...entryBox,
          selectFlag: entryBoxIds.includes(entryBox.entryBoxId) && !disabledFlag ? true : false,
          disabledFlag: disabledFlag,
        }
      });
      state.autoSubmReqUpdInfo.entryBoxList = newEntryBoxList
      let newSeminarList: any = []
      newSeminarList = action.payload.seminarList.map(seminar => {
        let displayEndDate = seminar.displayEndDate ? stringToDate(seminar.displayEndDate) : null
        let disabledFlag = displayEndDate && displayEndDate < systemDate ? true : false
        return {
          ...seminar,
          selectFlag: seminarIds.includes(seminar.seminarId) && !disabledFlag ? true : false,
          disabledFlag: disabledFlag,
        }
      })
      state.autoSubmReqUpdInfo.seminarList = newSeminarList

      let newSubmissionContentList: MCBHS310SubmissionsContentInfo[] = []
      let newContactContentList: MCBHS310ReferenceContentInfo[] = []
      let newPrivacyPolicyContentList: MCBHS310PrivacyPolicyContentInfo[] = []
      action.payload.submissionsContentList.map(i => {
        if (i.hiddenFlg === false) {
          newSubmissionContentList = [...newSubmissionContentList, i]
        }
      })
      action.payload.referenceContentList.map(i => {
        if (i.hiddenFlg === false) {
          newContactContentList = [...newContactContentList, i]
        }
      })
      action.payload.privacyPolicyContentList.map(i => {
        if (i.hiddenFlg === false) {
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList, i]
        }
      })
      state.autoSubmReqUpdInfo.submissionsContentList = newSubmissionContentList
      state.autoSubmReqUpdInfo.referenceContentList = newContactContentList
      state.autoSubmReqUpdInfo.thanksMessageTemplateList = action.payload.thanksMessageTemplateList
      state.autoSubmReqUpdInfo.privacyPolicyContentList = newPrivacyPolicyContentList

      let submissionsList: number[] = []
      let thanksList: number[] = []
      let contactList: number[] = []
      let personalList: number[] = []
      state.autoSubmReqUpdInfo.submissionsContentList.map(i => {
        if (i.submissionsContentId === Number(action.payload.submissionsSelectId)) {
          state.autoSubmReqUpdInfo.submissionsName = i.submissionsNameForCompany
          state.autoSubmReqUpdInfo.submissionsNameStudent = i.submissionsNameForStudent

          state.autoSubmReqUpdInfo.submissionsEntryFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? '1' : '0') : '0'
          state.autoSubmReqUpdInfo.submissionsResumeFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? '1' : '0') : '0'
          state.autoSubmReqUpdInfo.submissionsResearchFlg = i.researchOverviewsSubmissionFlg === true ? '1' : '0'
          state.autoSubmReqUpdInfo.submissionsOriginalFlg = i.originalQuestionSubmissionFlg === true ? '1' : '0'
          state.autoSubmReqUpdInfo.submissionsFileFlg = i.fileSubmissionFlg === true ? '1' : '0'

          state.autoSubmReqUpdInfo.submissionsNames = (state.autoSubmReqUpdInfo.submissionsEntryFlg === '1' ? 'エントリーシート、' : '') +
            (state.autoSubmReqUpdInfo.submissionsResumeFlg === '1' ? '履歴書、' : '') +
            (state.autoSubmReqUpdInfo.submissionsResearchFlg === '1' ? '研究概要書、' : '') +
            (state.autoSubmReqUpdInfo.submissionsOriginalFlg === '1' ? 'オリジナル設問、' : '') +
            (state.autoSubmReqUpdInfo.submissionsFileFlg === '1' ? 'ファイル、' : '')
        }
        submissionsList.push(i.submissionsContentId)
      })
      state.autoSubmReqUpdInfo.thanksMessageTemplateList.map(i => {
        if (i.thanksMailTemplateId === Number(action.payload.thanksMessageTemplateId)) {
          state.autoSubmReqUpdInfo.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
        }
        thanksList.push(i.thanksMailTemplateId)
      })
      state.autoSubmReqUpdInfo.referenceContentList.map(i => {
        if (i.inquiryId === Number(action.payload.referenceContentId)) {
          state.autoSubmReqUpdInfo.referenceContentName = i.inquiryRegisteredName
          state.autoSubmReqUpdInfo.referenceContentCompanyName = i.companyName
          state.autoSubmReqUpdInfo.referenceContentDepartmentNameAndManagerName = i.departmentNameAndManagerName
          state.autoSubmReqUpdInfo.referenceContentTelephoneNumber = i.telNumber
          state.autoSubmReqUpdInfo.referenceContentMailAddress = i.emailAddress
        }
        contactList.push(i.inquiryId)
      })
      state.autoSubmReqUpdInfo.privacyPolicyContentList.map(i => {
        if (i.myCareerBoxPrivacyPolicyId === Number(action.payload.personalDataHandlingId)) {
          state.autoSubmReqUpdInfo.personalDataHandlingName = i.privacyPolicyRegisterdName
        }
        personalList.push(i.myCareerBoxPrivacyPolicyId)
      })
      if (!submissionsList.includes(Number(action.payload.submissionsSelectId))) {
        state.autoSubmReqUpdInfo.submissionsNames = ''
        state.autoSubmReqUpdInfo.submissionsNameStudent = ''
        state.autoSubmReqUpdInfo.submissionsSelectId = ''
        state.autoSubmReqUpdInfo.submissionsName = ''
      }
      if (!contactList.includes(Number(action.payload.referenceContentId))) {
        state.autoSubmReqUpdInfo.referenceContentId = ''
        state.autoSubmReqUpdInfo.referenceContentName = ''
        state.autoSubmReqUpdInfo.referenceContentCompanyName = ''
        state.autoSubmReqUpdInfo.referenceContentDepartmentNameAndManagerName = ''
        state.autoSubmReqUpdInfo.referenceContentTelephoneNumber = ''
        state.autoSubmReqUpdInfo.referenceContentMailAddress = ''
      }
      if (!thanksList.includes(Number(action.payload.thanksMessageTemplateId))) {
        state.autoSubmReqUpdInfo.thanksMessageTemplateId = ''
        state.autoSubmReqUpdInfo.thanksMessageTemplateName = ''
      }
      if (!personalList.includes(Number(action.payload.personalDataHandlingId))) {
        state.autoSubmReqUpdInfo.personalDataHandlingId = ''
        state.autoSubmReqUpdInfo.personalDataHandlingName = ''
      }

      return state
    },
    clearTemplateSettingId320(state) {
      state.autoSubmReqUpdInfo.submissionRequestTemplateId = '0'
      return state
    },
    selectionSubReqTemplate320(state, action: PayloadAction<{ submissionRequestTemplateSettingId: string, values: MCBHS320ReturnDisplayOutDto }>) {
      const submissionRequestTemplateSettingId = action.payload.submissionRequestTemplateSettingId
      state.autoSubmReqUpdInfo = {
        ...action.payload.values,
        submissionRequestTemplateId: submissionRequestTemplateSettingId
      }
      if (submissionRequestTemplateSettingId !== '-1') {
        state.autoSubmReqUpdInfo.submissionRequestTemplateInfoList.map(template => {
          if (template && template.submissionRequestTemplateSettingId === submissionRequestTemplateSettingId) {

            let submissionsList: number[] = []
            state.autoSubmReqUpdInfo.submissionsContentList.map(i => {
              if (i.submissionsContentId === Number(template.submissionContentId)) {
                state.autoSubmReqUpdInfo.submissionsSelectId = String(i.submissionsContentId)
                state.autoSubmReqUpdInfo.submissionsName = i.submissionsNameForCompany
                state.autoSubmReqUpdInfo.submissionsNameStudent = i.submissionsNameForStudent
                state.autoSubmReqUpdInfo.submissionsEntryFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 1 ? '1' : '0') : '0'
                state.autoSubmReqUpdInfo.submissionsResumeFlg = i.entrySheetResumeSubmissionFlg === true ? (i.entrySheetResumeSubmissionCategory === 2 ? '1' : '0') : '0'
                state.autoSubmReqUpdInfo.submissionsResearchFlg = i.researchOverviewsSubmissionFlg === true ? '1' : '0'
                state.autoSubmReqUpdInfo.submissionsOriginalFlg = i.originalQuestionSubmissionFlg === true ? '1' : '0'
                state.autoSubmReqUpdInfo.submissionsFileFlg = i.fileSubmissionFlg === true ? '1' : '0'

                state.autoSubmReqUpdInfo.submissionsNames =
                  (state.autoSubmReqUpdInfo.submissionsEntryFlg === '1' ? 'エントリーシート、' : '') +
                  (state.autoSubmReqUpdInfo.submissionsResumeFlg === '1' ? '履歴書、' : '') +
                  (state.autoSubmReqUpdInfo.submissionsResearchFlg === '1' ? '研究概要書、' : '') +
                  (state.autoSubmReqUpdInfo.submissionsOriginalFlg === '1' ? 'オリジナル設問、' : '') +
                  (state.autoSubmReqUpdInfo.submissionsFileFlg === '1' ? 'ファイル、' : '')
              }
              submissionsList.push(i.submissionsContentId)
            })
            if (!submissionsList.includes(Number(template.submissionContentId))) {
              state.autoSubmReqUpdInfo.submissionsSelectId = ''
              state.autoSubmReqUpdInfo.submissionsName = ''
              state.autoSubmReqUpdInfo.submissionsNameStudent = ''
              state.autoSubmReqUpdInfo.submissionsNames = ''
              state.autoSubmReqUpdInfo.submissionsEntryFlg = ''
              state.autoSubmReqUpdInfo.submissionsResumeFlg = ''
              state.autoSubmReqUpdInfo.submissionsResearchFlg = ''
              state.autoSubmReqUpdInfo.submissionsOriginalFlg = ''
              state.autoSubmReqUpdInfo.submissionsFileFlg = ''
            }

            state.autoSubmReqUpdInfo.limitDateDetermineFlag = template.submissionDeadlineKind
            if (!isEmpty(template.submissionDeadlineKind) && !isEmpty(template.submissionDeadlineDay) && !isEmpty(template.submissionDeadlineHour)) {
              if (template.submissionDeadlineKind === '0') {
                let determineDateTime = template.submissionDeadlineDay + template.submissionDeadlineHour.toString() + '00';
                let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
                let formatedDate = determineDateTime.replace(pattern, '$1/$2/$3 $4:$5');
                state.autoSubmReqUpdInfo.determineDateTime = formatedDate;
                state.autoSubmReqUpdInfo.requestDayTimeLaterDay = ''
                state.autoSubmReqUpdInfo.requestDayTimeToTime = ''
                state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = ''
              } else if (template.submissionDeadlineKind === '2') {
                let anytimeReceptionEndDateTime = template.submissionDeadlineDay + template.submissionDeadlineHour.toString() + '00';
                let pattern = /(\d{4})(\d{1,2})(\d{1,2})(\d{1,2})(\d{2})/;
                let formatedDate = anytimeReceptionEndDateTime.replace(pattern, '$1/$2/$3 $4:$5');
                state.autoSubmReqUpdInfo.determineDateTime = ''
                state.autoSubmReqUpdInfo.requestDayTimeLaterDay = ''
                state.autoSubmReqUpdInfo.requestDayTimeToTime = ''
                state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = formatedDate;
              } else {
                state.autoSubmReqUpdInfo.determineDateTime = ''
                state.autoSubmReqUpdInfo.requestDayTimeLaterDay = template.submissionDeadlineDay
                state.autoSubmReqUpdInfo.requestDayTimeToTime = template.submissionDeadlineHour
                state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = ''
              }
            } else {
              state.autoSubmReqUpdInfo.limitDateDetermineFlag = '0'
              state.autoSubmReqUpdInfo.determineDateTime = ''
              state.autoSubmReqUpdInfo.requestDayTimeLaterDay = ''
              state.autoSubmReqUpdInfo.requestDayTimeToTime = ''
              state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = ''
            }

            state.autoSubmReqUpdInfo.reRequestReceptFlag = template.resubmitReceptionFlag
            state.autoSubmReqUpdInfo.senderCompanyName = template.senderCompanyName
            state.autoSubmReqUpdInfo.senderMailAddress = template.senderMailAddress

            let contactList: number[] = []
            state.autoSubmReqUpdInfo.referenceContentList.map(i => {
              if (i.inquiryId === Number(template.contactId)) {
                state.autoSubmReqUpdInfo.referenceContentId = String(i.inquiryId)
                state.autoSubmReqUpdInfo.referenceContentName = i.inquiryRegisteredName
                state.autoSubmReqUpdInfo.referenceContentCompanyName = i.companyName
                state.autoSubmReqUpdInfo.referenceContentDepartmentNameAndManagerName = i.departmentNameAndManagerName
                state.autoSubmReqUpdInfo.referenceContentTelephoneNumber = i.telNumber
                state.autoSubmReqUpdInfo.referenceContentMailAddress = i.emailAddress
              }
              contactList.push(i.inquiryId)
            })
            if (!contactList.includes(Number(template.contactId))) {
              state.autoSubmReqUpdInfo.referenceContentId = ''
              state.autoSubmReqUpdInfo.referenceContentName = ''
              state.autoSubmReqUpdInfo.referenceContentCompanyName = ''
              state.autoSubmReqUpdInfo.referenceContentDepartmentNameAndManagerName = ''
              state.autoSubmReqUpdInfo.referenceContentTelephoneNumber = ''
              state.autoSubmReqUpdInfo.referenceContentMailAddress = ''
            }

            state.autoSubmReqUpdInfo.requestSubject = template.subject
            state.autoSubmReqUpdInfo.requestText = template.body
            if (template.attachmentFileList && template.attachmentFileList.length > 0) {
              state.autoSubmReqUpdInfo.attachmentFileList = template.attachmentFileList
            } else {
              state.autoSubmReqUpdInfo.attachmentFileList = AttachmentFileInitialValues
            }
            state.autoSubmReqUpdInfo.homePageTitle1 = template.homePageTitle1 ? template.homePageTitle1 : ''
            state.autoSubmReqUpdInfo.homePageUrl1 = template.homePageUrl1 ? template.homePageUrl1 : ''
            state.autoSubmReqUpdInfo.homePageTitle2 = template.homePageTitle2 ? template.homePageTitle2 : ''
            state.autoSubmReqUpdInfo.homePageUrl2 = template.homePageUrl2 ? template.homePageUrl2 : ''
            state.autoSubmReqUpdInfo.homePageTitle3 = template.homePageTitle3 ? template.homePageTitle3 : ''
            state.autoSubmReqUpdInfo.homePageUrl3 = template.homePageUrl3 ? template.homePageUrl3 : ''

            state.autoSubmReqUpdInfo.thanksMessageSetFlag = template.thanksMessageSettingFlag
            if (template.thanksMessageSettingFlag === '1') {
              state.autoSubmReqUpdInfo.thanksSubject = template.thanksSubject
              state.autoSubmReqUpdInfo.thanksText = template.thanksBody
              if (isEmpty(template.thanksMessageTemplateId)) {
                state.autoSubmReqUpdInfo.thanksMessageTemplateId = ''
                state.autoSubmReqUpdInfo.thanksMessageTemplateName = ''
              } else {
                let thanksList: number[] = []
                state.autoSubmReqUpdInfo.thanksMessageTemplateList.map(i => {
                  if (i.thanksMailTemplateId === Number(template.thanksMessageTemplateId)) {
                    state.autoSubmReqUpdInfo.thanksMessageTemplateId = String(i.thanksMailTemplateId)
                    state.autoSubmReqUpdInfo.thanksMessageTemplateName = i.thanksMailTemplateRegisteredName
                  }
                  thanksList.push(i.thanksMailTemplateId)
                })
                if (!thanksList.includes(Number(template.thanksMessageTemplateId))) {
                  state.autoSubmReqUpdInfo.thanksMessageTemplateId = ''
                  state.autoSubmReqUpdInfo.thanksMessageTemplateName = ''
                }
              }
            } else {
              state.autoSubmReqUpdInfo.thanksMessageTemplateId = ''
              state.autoSubmReqUpdInfo.thanksMessageTemplateName = ''
              state.autoSubmReqUpdInfo.thanksSubject = ''
              state.autoSubmReqUpdInfo.thanksText = ''
            }

            let personalList: number[] = []
            state.autoSubmReqUpdInfo.privacyPolicyContentList.map(i => {
              if (i.myCareerBoxPrivacyPolicyId === Number(template.privacyPolicyId)) {
                state.autoSubmReqUpdInfo.personalDataHandlingId = String(i.myCareerBoxPrivacyPolicyId)
                state.autoSubmReqUpdInfo.personalDataHandlingName = i.privacyPolicyRegisterdName
              }
              personalList.push(i.myCareerBoxPrivacyPolicyId)
            })
            if (!personalList.includes(Number(template.privacyPolicyId))) {
              state.autoSubmReqUpdInfo.personalDataHandlingId = ''
              state.autoSubmReqUpdInfo.personalDataHandlingName = ''
            }
          }
        })
      } else {
        state.autoSubmReqUpdInfo.submissionsSelectId = ''
        state.autoSubmReqUpdInfo.submissionsName = ''
        state.autoSubmReqUpdInfo.submissionsNameStudent = ''
        state.autoSubmReqUpdInfo.submissionsNames = ''
        state.autoSubmReqUpdInfo.submissionsEntryFlg = ''
        state.autoSubmReqUpdInfo.submissionsResumeFlg = ''
        state.autoSubmReqUpdInfo.submissionsResearchFlg = ''
        state.autoSubmReqUpdInfo.submissionsOriginalFlg = ''
        state.autoSubmReqUpdInfo.submissionsFileFlg = ''
        state.autoSubmReqUpdInfo.limitDateDetermineFlag = '0'
        state.autoSubmReqUpdInfo.determineDateTime = ''
        state.autoSubmReqUpdInfo.requestDayTimeLaterDay = ''
        state.autoSubmReqUpdInfo.requestDayTimeToTime = ''
        state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = ''
        state.autoSubmReqUpdInfo.reRequestReceptFlag = '0'
        state.autoSubmReqUpdInfo.senderCompanyName = ''
        state.autoSubmReqUpdInfo.senderMailAddress = ''
        state.autoSubmReqUpdInfo.referenceContentId = ''
        state.autoSubmReqUpdInfo.referenceContentName = ''
        state.autoSubmReqUpdInfo.referenceContentCompanyName = ''
        state.autoSubmReqUpdInfo.referenceContentDepartmentNameAndManagerName = ''
        state.autoSubmReqUpdInfo.referenceContentTelephoneNumber = ''
        state.autoSubmReqUpdInfo.referenceContentMailAddress = ''
        state.autoSubmReqUpdInfo.requestSubject = ''
        state.autoSubmReqUpdInfo.requestText = ''
        state.autoSubmReqUpdInfo.attachmentFileList = AttachmentFileInitialValues
        state.autoSubmReqUpdInfo.homePageTitle1 = ''
        state.autoSubmReqUpdInfo.homePageUrl1 = ''
        state.autoSubmReqUpdInfo.homePageTitle2 = ''
        state.autoSubmReqUpdInfo.homePageUrl2 = ''
        state.autoSubmReqUpdInfo.homePageTitle3 = ''
        state.autoSubmReqUpdInfo.homePageUrl3 = ''
        state.autoSubmReqUpdInfo.thanksMessageSetFlag = '1'
        state.autoSubmReqUpdInfo.thanksMessageTemplateId = ''
        state.autoSubmReqUpdInfo.thanksMessageTemplateName = ''
        state.autoSubmReqUpdInfo.thanksSubject = ''
        state.autoSubmReqUpdInfo.thanksText = ''
        state.autoSubmReqUpdInfo.personalDataHandlingId = ''
        state.autoSubmReqUpdInfo.personalDataHandlingName = ''
      }
      return state
    },
    changeUseSetting320(state, action: PayloadAction<{ values: MCBHS320ReturnDisplayOutDto, value: string }>) {
      const value = action.payload.value
      state.autoSubmReqUpdInfo = JSON.parse(JSON.stringify(action.payload.values))
      state.autoSubmReqUpdInfo.useSetting = value
      if (value === '0') {
        state.autoSubmReqUpdInfo.useSettingStartDate = ''
        state.autoSubmReqUpdInfo.useSettingEndDate = ''
      }
      return state
    },
    changeDeadlineValue320(state, action: PayloadAction<{ values: MCBHS320ReturnDisplayOutDto, value: string }>) {
      const value = action.payload.value
      state.autoSubmReqUpdInfo = JSON.parse(JSON.stringify(action.payload.values))
      state.autoSubmReqUpdInfo.limitDateDetermineFlag = value
      if (value === '0') {
        state.autoSubmReqUpdInfo.requestDayTimeLaterDay = ''
        state.autoSubmReqUpdInfo.requestDayTimeToTime = ''
        state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = ''
      } else if (value === '1') {
        state.autoSubmReqUpdInfo.determineDateTime = ''
        state.autoSubmReqUpdInfo.anytimeReceptionEndDateTime = ''
      } else {
        state.autoSubmReqUpdInfo.determineDateTime = ''
        state.autoSubmReqUpdInfo.requestDayTimeLaterDay = ''
        state.autoSubmReqUpdInfo.requestDayTimeToTime = ''
        state.autoSubmReqUpdInfo.reRequestReceptFlag = '0'
      }
      return state
    },
    openApi320(state, action: PayloadAction<MCBHS310LinkResultDto>) {
      return state
    },
    getReloadSubmissions320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo = action.payload
      return state
    },
    setReloadSubmissions320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo.submissionsSelectId = ''
      state.autoSubmReqUpdInfo.submissionsNameStudent = ''
      state.autoSubmReqUpdInfo.submissionsName = ''
      state.autoSubmReqUpdInfo.submissionsNames = ''
      let newSubmissionContentList: MCBHS310SubmissionsContentInfo[] = []
      action.payload.submissionsContentList.map(i => {
        if (i.hiddenFlg === false) {
          newSubmissionContentList = [...newSubmissionContentList, i]
        }
      })
      state.autoSubmReqUpdInfo.submissionsContentList = newSubmissionContentList
      return state
    },
    getReloadThanksTemplate320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo = action.payload
      return state
    },
    setReloadThanksTemplate320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo.thanksMessageTemplateList = action.payload.thanksMessageTemplateList
      state.autoSubmReqUpdInfo.thanksMessageTemplateId = ''
      state.autoSubmReqUpdInfo.thanksMessageTemplateName = ''
      return state
    },
    getReloadReference320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo = action.payload
      return state
    },
    setReloadReference320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo.referenceContentId = ''
      state.autoSubmReqUpdInfo.referenceContentName = ''
      state.autoSubmReqUpdInfo.referenceContentCompanyName = ''
      state.autoSubmReqUpdInfo.referenceContentDepartmentNameAndManagerName = ''
      state.autoSubmReqUpdInfo.referenceContentTelephoneNumber = ''
      state.autoSubmReqUpdInfo.referenceContentMailAddress = ''
      let newReferenceSelectionList: MCBHS310ReferenceContentInfo[] = []
      action.payload.referenceContentList.map(i => {
        if (i.hiddenFlg === false) {
          newReferenceSelectionList = [...newReferenceSelectionList, i]
        }
      })
      state.autoSubmReqUpdInfo.referenceContentList = newReferenceSelectionList
      return state
    },
    getReloadPersonal320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo = action.payload
      return state
    },
    setReloadPersonal320(state, action: PayloadAction<MCBHS320ReturnDisplayOutDto>) {
      state.autoSubmReqUpdInfo.personalDataHandlingId = ''
      state.autoSubmReqUpdInfo.personalDataHandlingName = ''
      let newPrivacyPolicyContentList: MCBHS310PrivacyPolicyContentInfo[] = []
      action.payload.privacyPolicyContentList.map(i => {
        if (i.hiddenFlg === false) {
          newPrivacyPolicyContentList = [...newPrivacyPolicyContentList, i]
        }
      })
      state.autoSubmReqUpdInfo.privacyPolicyContentList = newPrivacyPolicyContentList
      return state
    },
    getPreview320(state, action: PayloadAction<{ formData: MCBHS310SubmitRequestPreviewRequest, setHasDisplayPreview: (value: boolean) => void }>) {
      return state
    },
    setPreview320(state, action: PayloadAction<string>) {
      state.returnBody = action.payload
      return state
    },
    deleteHomePageValue320(state, action: PayloadAction<{ values: MCBHS320ReturnDisplayOutDto, number: number }>) {
      state.autoSubmReqUpdInfo = action.payload.values
      if (action.payload.number == 1) {
        state.autoSubmReqUpdInfo.homePageTitle1 = ''
        state.autoSubmReqUpdInfo.homePageUrl1 = ''
      } else if (action.payload.number == 2) {
        state.autoSubmReqUpdInfo.homePageTitle2 = ''
        state.autoSubmReqUpdInfo.homePageUrl2 = ''
      } else if (action.payload.number == 3) {
        state.autoSubmReqUpdInfo.homePageTitle3 = ''
        state.autoSubmReqUpdInfo.homePageUrl3 = ''
      }
      return state
    },
    autoSubmissionRequestConfirm320(state, action: PayloadAction<{
      requestData: MCBHS320SubmissionRequestTemplateInputRequest,
      confirmData: ConfirmData,
      files: File[],
      entryBoxInfoList: MCBHS310EntryBoxInfo[],
      seminarInfoList: MCBHS310SeminarInfo[],
      oldAutoSubReqInfo: MCBHS320ReturnDisplayOutDto,
      executeFlag: string,
      nextAutoSendTime: string
    }>) {
      return state
    },
    deleteAutoSubReq(state, action: PayloadAction<MCBHS320DeleteAutoSubmissionRequest>) {
      return state
    },
    // MCBHS320_自動提出リクエスト編集 END

    // MCBHS340_自動提出リクエスト確認 START
    setAutoSubmissionRequestContentConfirm(state, action: PayloadAction<MCBHS340ReturnDisplayOutDto>) {
      state.mCBHS340ReturnDisplayOutDto = action.payload
      return state
    },
    setConfirmDialogOpen(state, action: PayloadAction<boolean>) {
      state.confirmDialogOpen = action.payload
      return state
    },
    setConfirmData(state, action: PayloadAction<ConfirmData>) {
      state.confirmData = action.payload
      return state
    },
    insertOrUpdateAutoSubmissionRequest(state, action: PayloadAction<{ registeredFile: File[], submitParams: MCBHS340AutoSubmissionRequestTemplateRequest }>) {
      return state
    },
    // MCBHS340_自動提出リクエスト確認 END

    // MCBHS350_自動提出リクエスト完了 START
    setCreatedFileInfo(state, action: PayloadAction<{
      fileManagementIds: string[],
      attachmentNames: string[],
      autoSubmissionRequestSettingId: number,
      sysVersionNumber: number,
    }>) {
      state.mCBHS350ReturnDisplayOutDto.fileManagementIds = action.payload.fileManagementIds
      state.mCBHS350ReturnDisplayOutDto.attachmentNames = action.payload.attachmentNames
      state.mCBHS350ReturnDisplayOutDto.autoSubmissionRequestSettingId = action.payload.autoSubmissionRequestSettingId
      state.mCBHS350ReturnDisplayOutDto.sysVersionNumber = action.payload.sysVersionNumber
      return state
    },
    getMaxCountTemp(state, action: PayloadAction<string>) {
      return state;
    },
    setMaxCountTemp(state, action: PayloadAction<{ countTemp: number, maxTempCount: number }>) {
      state.mCBHS350ReturnDisplayOutDto.countTemp = action.payload.countTemp
      state.mCBHS350ReturnDisplayOutDto.maxTempCount = action.payload.maxTempCount
      return state
    },
    setFinishDialogOpen(state, action: PayloadAction<boolean>) {
      state.finishDialogOpen = action.payload
      return state
    },
    setTemplateNameSelect(state, action: PayloadAction<{
      value: string,
      label: string,
      sysVersionNumber: string
    }>) {
      state.mCBHS350ReturnDisplayOutDto.templateNameOption = action.payload
      return state
    },
    createTemplate(state, action: PayloadAction<MCBHS350NewRequest>) {
      return state
    },
    orverWrite(state, action: PayloadAction<MCBHS350OverwriteRequest>) {
      return state
    },
    closeAutoSubReqAllDialog(state) {
      return state
    },
    // MCBHS350_自動提出リクエスト完了 END
  }
})

// MCBHS300_自動提出リクエスト設定一覧 START
// 日付変換(日付形式)
// 与えられた文字列を日付に変換する。
export const stringToDate = (target: string) => {
  return moment(target).toDate()
}

// 日付変換(日時形式)
// 与えられた文字列を日付に変換する。
export const stringToDateTime = (target: string) => {
  return moment(target).toDate()
}

// 文字列変換(日付)
// 与えられた日付を文字列に変換する。
export const dateToString = (target: Date) => {
  return moment(target).format('YYYY/MM/DD')
}

// 文字列変換(日時)
// 与えられた日付を文字列に変換する。
export const dateTimeToString = (target: Date) => {
  return moment(target).format('YYYY/MM/DD HH:mm')
}

// 改行
export const addNewLinesChar = (str: string, num: number) => {
  let result = '';
  for (let i = 0; i < str.length; i += num) {
    let chunk = str.substr(i, num);
    result += chunk + ((i + num) < str.length ? '\n' : '');
  }
  return result;
}
// MCBHS300_自動提出リクエスト設定一覧 END

export const {
  // MCBHS300_自動提出リクエスト設定一覧 START
  selectAutoSubmissionRequestList,
  setAutoSubmissionRequestResultInfo,
  editAutoSubmissionRequest,
  updateAutoSubmissionRequestSort,
  checkAutoSubReqCount,
  setCreateDialogOpen,
  setUpdateDialogOpen,
  // 27P #10663 start
  setLoadingFlag,
  setMaxAutoSubReqTemplateCount,
  // 27P #10663 end
  // MCBHS300_自動提出リクエスト設定一覧 END
  // MCBHS310_自動提出リクエスト登録 START
  setAutoSubmissionReqAddInit,
  clearTemplateSettingId,
  selectionSubReqTemplate,
  changeUseSetting,
  changeDeadlineValue,
  openApi,
  getReloadSubmissions,
  setReloadSubmissions,
  getReloadThanksTemplate,
  setReloadThanksTemplate,
  getReloadReference,
  setReloadReference,
  getReloadPersonal,
  setReloadPersonal,
  getPreview,
  setPreview,
  deleteHomePageValue,
  autoSubmissionRequestConfirm,
  openModalWithArgMessage,
  closeModelArgs,
  // MCBHS310_自動提出リクエスト登録 END
  // MCBHS320_自動提出リクエスト編集 START
  setAutoSubmissionReqUpdInit,
  clearTemplateSettingId320,
  selectionSubReqTemplate320,
  changeUseSetting320,
  changeDeadlineValue320,
  openApi320,
  getReloadSubmissions320,
  setReloadSubmissions320,
  getReloadThanksTemplate320,
  setReloadThanksTemplate320,
  getReloadReference320,
  setReloadReference320,
  getReloadPersonal320,
  setReloadPersonal320,
  getPreview320,
  setPreview320,
  deleteHomePageValue320,
  autoSubmissionRequestConfirm320,
  deleteAutoSubReq,
  // MCBHS320_自動提出リクエスト編集 END
  // MCBHS340_自動提出リクエスト確認 START
  setAutoSubmissionRequestContentConfirm,
  setConfirmDialogOpen,
  setConfirmData,
  insertOrUpdateAutoSubmissionRequest,
  // MCBHS340_自動提出リクエスト確認 END
  // MCBHS350_自動提出リクエスト完了 START
  setCreatedFileInfo,
  getMaxCountTemp,
  setMaxCountTemp,
  setFinishDialogOpen,
  setTemplateNameSelect,
  createTemplate,
  orverWrite,
  closeAutoSubReqAllDialog,
  // MCBHS350_自動提出リクエスト完了 END
} = autoSubmissionRequestSlice.actions
export default autoSubmissionRequestSlice.reducer