import {
  autoSubmissionRequestConfirm, checkAutoSubReqCount, getPreview, getReloadPersonal, getReloadReference, getReloadSubmissions,
  getReloadThanksTemplate, insertOrUpdateAutoSubmissionRequest, openApi, openModalWithArgMessage, setAutoSubmissionReqAddInit,
  setAutoSubmissionRequestContentConfirm, setConfirmData, setConfirmDialogOpen, setCreateDialogOpen, setFinishDialogOpen,
  setPreview, setReloadPersonal, setReloadReference, setReloadSubmissions, setReloadThanksTemplate, getMaxCountTemp, setMaxCountTemp,
  setCreatedFileInfo, createTemplate, orverWrite, setUpdateDialogOpen, closeAutoSubReqAllDialog, selectAutoSubmissionRequestList,
  updateAutoSubmissionRequestSort, editAutoSubmissionRequest, setAutoSubmissionRequestResultInfo, MCBHS300AutoSubReqResultInfo,
  getPreview320, getReloadPersonal320, getReloadReference320, getReloadSubmissions320, getReloadThanksTemplate320, openApi320,
  autoSubmissionRequestConfirm320, setAutoSubmissionReqUpdInit, setReloadSubmissions320, setReloadReference320, setReloadThanksTemplate320,
  setReloadPersonal320, setPreview320, stringToDate, deleteAutoSubReq, setLoadingFlag, setMaxAutoSubReqTemplateCount
} from "reducers/autoSubmissionRequestReducer"
import { openModal, openModalWithArg, openSnackbar } from "reducers/messageReducer"
import { all, call, put, select, takeLeading } from "redux-saga/effects"
import { getGlobalTargetYear } from '../selectors/authSelectors'
import { MCBHS310InitDisplayRequest } from "types/MCBHS310/MCBHS310InitDisplayRequest"
import {
  autoSubmissionRequestAddApi, selectApiLinkRequest, reloadPersonalRequest, reloadReferenceRequest,
  reloadSubmissionsSetRequest, reloadThanksTemplateRequest, previewRequest, autoSubmissionRequestConfirmApi
} from "apis/MCBHS310Api"
import { MCBHS310ReturnDisplayOutDto } from "pages/MCBHS310/formConfig"
import { popupParams } from "utils/misc"
import axios from "axios"
import { getToken } from "utils/auth"
import { getMessage, getMessageEx } from "common/messageUtil"
import { isEmpty, now } from "common/generalUtil"
import { MCBHS340InitDisplayRequest } from "types/MCBHS340/MCBHS340InitDisplayRequest"
import { MCBHS340ReturnDisplayOutDto } from "pages/MCBHS340/formConfig"
import { initRequest, insertOrUpdateRequest } from "apis/MCBHS340Api"
import { getMaxCountTempApi, overwriteTemplateRequest, saveNewTemplateRequest } from "apis/MCBHS350Api"
import { MCBHS350InitRequest } from "types/MCBHS350/MCBHS350InitRequest"
import { selectAutoSubmissionRequestListRequest, updateAutoSubmissionRequestSortRequest, addAutoSubmissionRequestRequest } from "apis/MCBHS300Api"
import { MCBHS300InitDisplayRequest } from "types/MCBHS300/MCBHS300InitDisplayRequest"
import {
  autoSubmissionRequestConfirm320Api,
  autoSubmissionRequestUpdApi, deleteAutoSubmissionRequestApi, previewRequest320, reloadPersonalRequest320,
  reloadReferenceRequest320, reloadSubmissionsSetRequest320, reloadThanksTemplateRequest320, selectApiLinkRequest320
} from "apis/MCBHS320Api"
import { handleBusinessCheck, handleBusinessCheck2 } from "pages/MCBHS310/methodConfig"
import { magiContants } from "utils/contants"
import { getBatchStartDateTime, getOperatingStatus, handle320BusinessCheck, handle320BusinessCheck2 } from "pages/MCBHS320/methodConfig"
import { MCBHS320ReturnDisplayOutDto } from "pages/MCBHS320/formConfig"
import { isFileExist } from "common/businessUtil"

// MCBHS300_自動提出リクエスト設定一覧 START
function* selectAutoSubmissionRequestListSaga(action: ReturnType<typeof selectAutoSubmissionRequestList>) {
  try {
    const data = yield call(selectAutoSubmissionRequestListRequest, action.payload);
    yield put(setAutoSubmissionRequestResultInfo(data));
  } catch (error) {
    // 27P #10663 start
    // 27P #10759 start
    if (error.message) {
      const messageResult: Array<string> = error.message.split(",");
      yield put(openModal(messageResult[0]))
      if (messageResult[0] == magiContants.MESSAGECODE_MCBHS300_001 && messageResult[1]) {
        yield put(setMaxAutoSubReqTemplateCount(messageResult[1]));
      }
    }
    // 27P #10759 end
  } finally {
    yield put(setLoadingFlag(true))
    // 27P #10663 end
  }
}
function* updateAutoSubmissionRequestSortSaga(action: ReturnType<typeof updateAutoSubmissionRequestSort>) {
  try {
    yield call(updateAutoSubmissionRequestSortRequest, action.payload.updateOrderList);
    const data = yield call(selectAutoSubmissionRequestListRequest, action.payload.selectParam);
    yield put(setAutoSubmissionRequestResultInfo(data));
  } catch (error) {
    // 27P #10663 start
    // 27P #10759 start
    if (error.message) {
      const messageResult: Array<string> = error.message.split(",");
      yield put(openModal(messageResult[0]))
      if (messageResult[0] == magiContants.MESSAGECODE_MCBHS300_001 && messageResult[1]) {
        yield put(setMaxAutoSubReqTemplateCount(messageResult[1]));
      }
    }
    // 27P #10759 end
  } finally {
    yield put(setLoadingFlag(true))
    // 27P #10663 end
  }
}
function* editAutoSubmissionRequestSaga(action: ReturnType<typeof editAutoSubmissionRequest>) {
  try {
    const data: MCBHS320ReturnDisplayOutDto = yield call(autoSubmissionRequestUpdApi, action.payload);
    yield put(setAutoSubmissionReqUpdInit(data))
    yield put(setUpdateDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
export function* checkAutoSubReqCountSaga(action: ReturnType<typeof checkAutoSubReqCount>) {
  try {
    yield call(addAutoSubmissionRequestRequest);
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(getGlobalTargetYear)
    const param: MCBHS310InitDisplayRequest = {
      targetYear: targetYear,
    }
    const data: MCBHS310ReturnDisplayOutDto = yield call(autoSubmissionRequestAddApi, param)
    yield put(setAutoSubmissionReqAddInit(data))
    yield put(setCreateDialogOpen(true))
  } catch (error) {
    yield put(openModal(getMessageEx(error.message, action.payload)))
  }
}
// MCBHS300_自動提出リクエスト設定一覧 END


// MCBHS310_自動提出リクエスト登録 START
function* selectApiLinkSaga(action: ReturnType<typeof openApi>) {
  try {
    yield call(selectApiLinkRequest)
    const params = popupParams(1280, 948)
    const authToken = action.payload.authToken
    const sourceSystemType = '1'
    const sourceCompanyCode = action.payload.sourceCompanyCode
    const annualParam = action.payload.annualParam
    const url = action.payload.url + '?sourceSystemType=' + sourceSystemType + '&sourceCompanyCode=' + sourceCompanyCode + annualParam
    axios.get(url, {
      withCredentials: true,
      headers: {
        Authorization: getToken(),
      },
    }).then(res => {
      let newWindow = window.open(url, "_blank", params)
    }).catch(error => {
      console.log(error)
    })
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* reloadSubmissionsSetRequestSaga() {
  try {
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(getGlobalTargetYear)
    const param = {
      targetYear: targetYear
    }
    const data: MCBHS310ReturnDisplayOutDto = yield call(reloadSubmissionsSetRequest, param)
    yield put(setReloadSubmissions(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* reloadReferenceRequestSaga() {
  try {
    const data: MCBHS310ReturnDisplayOutDto = yield call(reloadReferenceRequest)
    yield put(setReloadReference(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* reloadThanksTemplateRequestSaga() {
  try {
    const data: MCBHS310ReturnDisplayOutDto = yield call(reloadThanksTemplateRequest)
    yield put(setReloadThanksTemplate(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* reloadPersonalRequestSaga() {
  try {
    const data: MCBHS310ReturnDisplayOutDto = yield call(reloadPersonalRequest)
    yield put(setReloadPersonal(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* previewSaga(action: ReturnType<typeof getPreview>) {
  try {
    const formData = action.payload.formData
    const serverResponse: string = yield call(previewRequest, formData)
    yield put(setPreview(serverResponse))
    action.payload.setHasDisplayPreview(true)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* autoSubmissionRequestConfirmSaga(action: ReturnType<typeof autoSubmissionRequestConfirm>) {
  try {
    const confirmData = action.payload.confirmData;
    const files: File[] = action.payload.files
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile: boolean = yield call(isFileExist, file)
        if (!isFile) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBHS310_023)))
          return
        }
      }
    }
    if (action.payload.executeFlag === '1') {
      const messageInfo: {
        messageId: string,
        args: string[]
      } = yield call(handleBusinessCheck, confirmData, action.payload.entryBoxInfoList, action.payload.seminarInfoList)
      if (!isEmpty(messageInfo.messageId)) {
        if (messageInfo.args.length > 0) {
          yield put(openModalWithArgMessage({ messageId: messageInfo.messageId, args: messageInfo.args }))
          return
        } else {
          yield put(openModal(getMessage(messageInfo.messageId)))
          return
        }
      }
    }
    const messageId2: string = yield call(handleBusinessCheck2, confirmData, files)
    if (!isEmpty(messageId2)) {
      yield put(openModal(getMessage(messageId2)))
      return
    }
    yield call(autoSubmissionRequestConfirmApi, { registeredFileName: files, submitParams: action.payload.requestData })

    let fileFlag: boolean = false
    if (files.length > 0 || confirmData.requestAttachmentId.length > 0) {
      fileFlag = true
    }
    let homePageTitle: string[] = [];
    let homePageUrl: string[] = [];
    if (confirmData.homePageTitle1 !== '' && confirmData.homePageUrl1 !== '') {
      homePageTitle[0] = confirmData.homePageTitle1
    } else {
      homePageTitle[0] = ''
    }
    if (confirmData.homePageUrl1 !== '') {
      homePageUrl[0] = confirmData.homePageUrl1
    }
    if (confirmData.homePageTitle2 !== '' && confirmData.homePageUrl2 !== '') {
      homePageTitle[1] = confirmData.homePageTitle2
    } else {
      homePageTitle[1] = ''
    }
    if (confirmData.homePageUrl2 !== '') {
      homePageUrl[1] = confirmData.homePageUrl2
    }
    if (confirmData.homePageTitle3 !== '' && confirmData.homePageUrl3 !== '') {
      homePageTitle[2] = confirmData.homePageTitle3
    } else {
      homePageTitle[2] = ''
    }
    if (confirmData.homePageUrl3 !== '') {
      homePageUrl[2] = confirmData.homePageUrl3
    }
    let requestMessageBody = confirmData.requestText
    let thanksMessageBody = confirmData.thanksMessageSetFlag === 1 ? confirmData.thanksText : ''
    if (!isEmpty(requestMessageBody)) {
      requestMessageBody = requestMessageBody.replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
    }
    if (!isEmpty(thanksMessageBody)) {
      thanksMessageBody = thanksMessageBody.replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
    }
    requestMessageBody = requestMessageBody.replace(/{@request_url}/g, '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>')
    thanksMessageBody = thanksMessageBody.replace(/{@request_url}/g, '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>')
    const data2: MCBHS340InitDisplayRequest = {
      autoSubmissionRequestSettingId: confirmData.autoSubmissionRequestSettingId,
      targetYear: confirmData.targetYear,
      requestMessageBody: requestMessageBody,
      thanksMessageBody: thanksMessageBody,
      submissionContentName: confirmData.submissionsName,
      attachmentFlg: fileFlag,
      homePageTitleList: homePageTitle,
      homePageUrlList: homePageUrl
    }
    const data3: MCBHS340ReturnDisplayOutDto = yield call(initRequest, data2)
    yield put(setAutoSubmissionRequestContentConfirm(data3))
    yield put(setConfirmData(confirmData))
    yield put(setConfirmDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCBHS310_自動提出リクエスト登録 END

// MCBHS320_自動提出リクエスト編集 START
function* selectApiLink320Saga(action: ReturnType<typeof openApi320>) {
  try {
    yield call(selectApiLinkRequest320)
    const params = popupParams(1280, 948)
    const authToken = action.payload.authToken
    const sourceSystemType = '1'
    const sourceCompanyCode = action.payload.sourceCompanyCode
    const annualParam = action.payload.annualParam
    const url = action.payload.url + '?sourceSystemType=' + sourceSystemType + '&sourceCompanyCode=' + sourceCompanyCode + annualParam
    axios.get(url, {
      withCredentials: true,
      headers: {
        Authorization: getToken(),
      },
    }).then(res => {
      let newWindow = window.open(url, "_blank", params)
    }).catch(error => {
      console.log(error)
    })
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* reloadSubmissionsSetRequest320Saga(action: ReturnType<typeof getReloadSubmissions320>) {
  try {
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(getGlobalTargetYear)
    const param = {
      targetYear: targetYear,
      autoSubmissionRequestSettingId: action.payload.autoSubmissionRequestSettingId
    }
    const data: MCBHS320ReturnDisplayOutDto = yield call(reloadSubmissionsSetRequest320, param)
    yield put(setReloadSubmissions320(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* reloadReferenceRequest320Saga() {
  try {
    const data: MCBHS320ReturnDisplayOutDto = yield call(reloadReferenceRequest320)
    yield put(setReloadReference320(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* reloadThanksTemplateRequest320Saga() {
  try {
    const data: MCBHS320ReturnDisplayOutDto = yield call(reloadThanksTemplateRequest320)
    yield put(setReloadThanksTemplate320(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* reloadPersonalRequest320Saga() {
  try {
    const data: MCBHS320ReturnDisplayOutDto = yield call(reloadPersonalRequest320)
    yield put(setReloadPersonal320(data))
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* preview320Saga(action: ReturnType<typeof getPreview320>) {
  try {
    const formData = action.payload.formData
    const serverResponse: string = yield call(previewRequest320, formData)
    yield put(setPreview320(serverResponse))
    action.payload.setHasDisplayPreview(true)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* autoSubmissionRequestConfirm320Saga(action: ReturnType<typeof autoSubmissionRequestConfirm320>) {
  try {
    const confirmData = action.payload.confirmData;
    const files: File[] = action.payload.files
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile: boolean = yield call(isFileExist, file)
        if (!isFile) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBHS320_024)))
          return
        }
      }
    }
    if (action.payload.executeFlag === '1') {
      const messageInfo: {
        messageId: string,
        args: string[]
      } = yield call(handle320BusinessCheck, confirmData, action.payload.entryBoxInfoList, action.payload.seminarInfoList)
      if (!isEmpty(messageInfo.messageId)) {
        if (messageInfo.args.length > 0) {
          yield put(openModalWithArgMessage({ messageId: messageInfo.messageId, args: messageInfo.args }))
          return
        } else {
          yield put(openModal(getMessage(messageInfo.messageId)))
          return
        }
      }
    }
    // エントリーボックス／セミナー設定条件の表示終了日チェック
    if (['1', '2'].includes(action.payload.executeFlag)) {
      for (var i = 0; i < action.payload.entryBoxInfoList.length; i++) {
        let entryBoxInfo = action.payload.entryBoxInfoList[i]
        if (entryBoxInfo.disabledFlag) {
          yield put(openModalWithArgMessage({ messageId: magiContants.MESSAGECODE_MCBHS320_034, args: [] }))
          return
        }
      }
      for (var i = 0; i < action.payload.seminarInfoList.length; i++) {
        let seminarInfo = action.payload.seminarInfoList[i]
        if (seminarInfo.disabledFlag) {
          yield put(openModalWithArgMessage({ messageId: magiContants.MESSAGECODE_MCBHS320_034, args: [] }))
          return
        }
      }
    }
    // 利用設定変更チェック
    if (['1', '2', '3'].includes(action.payload.executeFlag)) {
      const hours = action.payload.nextAutoSendTime
      const oldAutoSubReqInfo = action.payload.oldAutoSubReqInfo
      const confirmData = action.payload.confirmData
      if (oldAutoSubReqInfo.useSetting === '1' && confirmData.useSetting == 0) {
        yield put(openModalWithArgMessage({ messageId: magiContants.MESSAGECODE_MCBHS320_015, args: [hours] }))
        return
      }
      if (!isEmpty(oldAutoSubReqInfo.useSettingStartDate) && !isEmpty(oldAutoSubReqInfo.useSettingEndDate)
        && !isEmpty(confirmData.useSettingStartDate) && !isEmpty(confirmData.useSettingEndDate)) {
        const systemDate = now()
        const oldUseSettingStartDate = stringToDate(oldAutoSubReqInfo.useSettingStartDate)
        const oldBatchStartDateTime = getBatchStartDateTime(stringToDate(oldAutoSubReqInfo.useSettingEndDate))
        const newUseSettingStartDate = stringToDate(confirmData.useSettingStartDate)
        const newBatchStartDateTime = getBatchStartDateTime(stringToDate(confirmData.useSettingEndDate))
        let oldOperatingStatus = getOperatingStatus(systemDate, oldUseSettingStartDate, oldBatchStartDateTime)
        let newOperatingStatus = getOperatingStatus(systemDate, newUseSettingStartDate, newBatchStartDateTime)
        if (oldOperatingStatus == '2' && newOperatingStatus == '3') {
          yield put(openModalWithArgMessage({ messageId: magiContants.MESSAGECODE_MCBHS320_015, args: [hours] }))
          return
        }
      }
    }
    const messageId2: string = yield call(handle320BusinessCheck2, confirmData, files)
    if (!isEmpty(messageId2)) {
      yield put(openModal(getMessage(messageId2)))
      return
    }
    yield call(autoSubmissionRequestConfirm320Api, { registeredFileName: files, submitParams: action.payload.requestData })

    let fileFlag: boolean = false
    if (files.length > 0 || confirmData.requestAttachmentId.length > 0) {
      fileFlag = true
    }
    let homePageTitle: string[] = [];
    let homePageUrl: string[] = [];
    if (confirmData.homePageTitle1 !== '' && confirmData.homePageUrl1 !== '') {
      homePageTitle[0] = confirmData.homePageTitle1
    } else {
      homePageTitle[0] = ''
    }
    if (confirmData.homePageUrl1 !== '') {
      homePageUrl[0] = confirmData.homePageUrl1
    }
    if (confirmData.homePageTitle2 !== '' && confirmData.homePageUrl2 !== '') {
      homePageTitle[1] = confirmData.homePageTitle2
    } else {
      homePageTitle[1] = ''
    }
    if (confirmData.homePageUrl2 !== '') {
      homePageUrl[1] = confirmData.homePageUrl2
    }
    if (confirmData.homePageTitle3 !== '' && confirmData.homePageUrl3 !== '') {
      homePageTitle[2] = confirmData.homePageTitle3
    } else {
      homePageTitle[2] = ''
    }
    if (confirmData.homePageUrl3 !== '') {
      homePageUrl[2] = confirmData.homePageUrl3
    }
    let requestMessageBody = confirmData.requestText
    let thanksMessageBody = confirmData.thanksMessageSetFlag === 1 ? confirmData.thanksText : ''
    if (!isEmpty(requestMessageBody)) {
      requestMessageBody = requestMessageBody.replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
    }
    if (!isEmpty(thanksMessageBody)) {
      thanksMessageBody = thanksMessageBody.replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
    }
    requestMessageBody = requestMessageBody.replace(/{@request_url}/g, '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>')
    thanksMessageBody = thanksMessageBody.replace(/{@request_url}/g, '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>')
    const data2: MCBHS340InitDisplayRequest = {
      autoSubmissionRequestSettingId: confirmData.autoSubmissionRequestSettingId,
      targetYear: confirmData.targetYear,
      requestMessageBody: requestMessageBody,
      thanksMessageBody: thanksMessageBody,
      submissionContentName: confirmData.submissionsName,
      attachmentFlg: fileFlag,
      homePageTitleList: homePageTitle,
      homePageUrlList: homePageUrl
    }
    const data3: MCBHS340ReturnDisplayOutDto = yield call(initRequest, data2)
    yield put(setAutoSubmissionRequestContentConfirm(data3))
    yield put(setConfirmData(confirmData))
    yield put(setConfirmDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* deleteAutoSubmissionRequestSaga(action: ReturnType<typeof deleteAutoSubReq>) {
  try {
    yield call(deleteAutoSubmissionRequestApi, action.payload)
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(getGlobalTargetYear)
    const param: MCBHS300InitDisplayRequest = {
      targetYear: targetYear,
    }
    // 27P #10663 start
    yield put(setUpdateDialogOpen(false))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBHS320_031)))
    const data: MCBHS300AutoSubReqResultInfo = yield call(selectAutoSubmissionRequestListRequest, param);
    yield put(setAutoSubmissionRequestResultInfo(data));
  } catch (error) {
    // 27P #10759 start
    if (error.message) {
      const messageResult: Array<string> = error.message.split(",");
      yield put(openModal(messageResult[0]))
      if (messageResult[0] == magiContants.MESSAGECODE_MCBHS300_001 && messageResult[1]) {
        yield put(setMaxAutoSubReqTemplateCount(messageResult[1]));
      }
    }
    // 27P #10759 end
  } finally {
    yield put(setLoadingFlag(true))
    // 27P #10663 end
  }
}
// MCBHS320_自動提出リクエスト編集 END

// MCBHS340_自動提出リクエスト確認 START
function* insertOrUpdateSaga(action: ReturnType<typeof insertOrUpdateAutoSubmissionRequest>) {
  try {

    const sendFileData: File[] = action.payload.registeredFile
    for (var i = 0; i < sendFileData.length; i++) {
      const file = sendFileData[i]
      if (file) {
        const isFile: boolean = yield call(isFileExist, file)
        if (!isFile) {
          yield put(setConfirmDialogOpen(false))
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCBHS340_012)))
          return
        }
      }
    }

    const fileData: {
      fileManagementIds: string[],
      attachmentNames: string[],
      autoSubmissionRequestSettingId: number,
      sysVersionNumber: number,
    } = yield call(insertOrUpdateRequest, {
      registeredFileName: action.payload.registeredFile,
      submitParams: action.payload.submitParams
    })
    yield put(setCreatedFileInfo(fileData));
    yield put(setFinishDialogOpen(true));
    if (isEmpty(action.payload.submitParams.autoSubmissionRequestSettingId)) {
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBHS340_020)))
    } else {
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBHS340_023)))
    }
  } catch (error) {
    yield put(setConfirmDialogOpen(false))
    if (error.message) {
      const messageResult: Array<string> = error.message.split(",");
      if (messageResult.length == 1) {
        yield put(openModal(messageResult[0]))
      } else {
        const [messageId, ...args] = messageResult
        yield put(openModalWithArg({ messageId, args }))
      }
    }
  }
}
//  MCBHS340_自動提出リクエスト確認 END

// MCBHS350_自動提出リクエスト完了 START
function* getMaxCountTempSaga(action: ReturnType<typeof getMaxCountTemp>) {
  try {
    const data: MCBHS350InitRequest = {
      selectedRequestTemplateSettingId: "",
      displayType: isEmpty(action.payload) ? 0 : 1,
      requestTemplateSettingId: "",
      requestTemplateName: ""
    }
    const result: {
      countTemp: number,
      maxTempCount: number
    } = yield call(getMaxCountTempApi, data)
    yield put(setMaxCountTemp(result))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* createTemplateSaga(action: ReturnType<typeof createTemplate>) {
  try {
    const message: string = yield call(saveNewTemplateRequest, action.payload)
    yield put(openSnackbar(getMessage(message)));
  } catch (error) {
    if (error.message) {
      const messageResult: Array<string> = error.message.split(",");
      if (messageResult.length == 1) {
        yield put(openModal(messageResult[0]))
      } else {
        const [messageId, ...args] = messageResult
        yield put(openModalWithArg({ messageId, args }))
      }
    }
  }
}

function* orverWriteSaga(action: ReturnType<typeof orverWrite>) {
  try {
    const message: string = yield call(overwriteTemplateRequest, action.payload)
    yield put(openSnackbar(getMessage(message)));
  } catch (error) {
    yield put(openModal(error.message))
  }
}
export function* closeAutoSubReqAllDialogSaga() {
  try {
    yield put(setFinishDialogOpen(false))
    yield put(setConfirmDialogOpen(false))
    yield put(setCreateDialogOpen(false))
    yield put(setUpdateDialogOpen(false))
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(getGlobalTargetYear)
    const param: MCBHS300InitDisplayRequest = {
      targetYear: targetYear,
    }
    const data: MCBHS300AutoSubReqResultInfo = yield call(selectAutoSubmissionRequestListRequest, param);
    yield put(setAutoSubmissionRequestResultInfo(data));
  } catch (error) {
    // 27P #10663 start
    // 27P #10759 start
    if (error.message) {
      const messageResult: Array<string> = error.message.split(",");
      yield put(openModal(messageResult[0]))
      if (messageResult[0] == magiContants.MESSAGECODE_MCBHS300_001 && messageResult[1]) {
        yield put(setMaxAutoSubReqTemplateCount(messageResult[1]));
      }
    }
    // 27P #10759 end
  } finally {
    yield put(setLoadingFlag(true))
    // 27P #10663 end
  }
}
// MCBHS350_自動提出リクエスト完了 END

export default function* autoSubmissionRequestSaga() {
  yield all([
    // MCBHS300_自動提出リクエスト設定一覧 START
    takeLeading(selectAutoSubmissionRequestList, selectAutoSubmissionRequestListSaga),
    takeLeading(updateAutoSubmissionRequestSort, updateAutoSubmissionRequestSortSaga),
    takeLeading(editAutoSubmissionRequest, editAutoSubmissionRequestSaga),
    takeLeading(checkAutoSubReqCount, checkAutoSubReqCountSaga),
    // MCBHS300_自動提出リクエスト設定一覧 END
    // MCBHS310_自動提出リクエスト登録 START
    takeLeading(openApi, selectApiLinkSaga),
    takeLeading(getReloadSubmissions, reloadSubmissionsSetRequestSaga),
    takeLeading(getReloadReference, reloadReferenceRequestSaga),
    takeLeading(getReloadThanksTemplate, reloadThanksTemplateRequestSaga),
    takeLeading(getReloadPersonal, reloadPersonalRequestSaga),
    takeLeading(getPreview, previewSaga),
    takeLeading(autoSubmissionRequestConfirm, autoSubmissionRequestConfirmSaga),
    // MCBHS310_自動提出リクエスト登録 END
    // MCBHS320_自動提出リクエスト編集 START
    takeLeading(openApi320, selectApiLink320Saga),
    takeLeading(getReloadSubmissions320, reloadSubmissionsSetRequest320Saga),
    takeLeading(getReloadReference320, reloadReferenceRequest320Saga),
    takeLeading(getReloadThanksTemplate320, reloadThanksTemplateRequest320Saga),
    takeLeading(getReloadPersonal320, reloadPersonalRequest320Saga),
    takeLeading(getPreview320, preview320Saga),
    takeLeading(autoSubmissionRequestConfirm320, autoSubmissionRequestConfirm320Saga),
    takeLeading(deleteAutoSubReq, deleteAutoSubmissionRequestSaga),
    // MCBHS320_自動提出リクエスト編集 END
    // MCBHS340_自動提出リクエスト確認 START
    takeLeading(insertOrUpdateAutoSubmissionRequest, insertOrUpdateSaga),
    //  MCBHS340_自動提出リクエスト確認 END
    // MCBHS350_自動提出リクエスト完了 START
    takeLeading(getMaxCountTemp, getMaxCountTempSaga),
    takeLeading(createTemplate, createTemplateSaga),
    takeLeading(orverWrite, orverWriteSaga),
    takeLeading(closeAutoSubReqAllDialog, closeAutoSubReqAllDialogSaga),
    // MCBHS350_自動提出リクエスト完了 END
  ])
}