import request from 'utils/request'
import {MCBHS300InitDisplayRequest} from "types/MCBHS300/MCBHS300InitDisplayRequest"
import { MCBHS300EditAutoSubmissionRequest } from "types/MCBHS300/MCBHS300EditAutoSubmissionRequest"
import { AutoSubReqResultDto} from "reducers/autoSubmissionRequestReducer"

export const selectAutoSubmissionRequestListRequest = (param:MCBHS300InitDisplayRequest) =>
  request({
    url: '/MCBHS300/init',
    method: 'post',
    data: param,
})

export const addAutoSubmissionRequestRequest = () =>
  request({
    url:  `/MCBHS300/register`,
    method: 'post',
})

export const findAutoSubmissionRequestRequest = (param: MCBHS300EditAutoSubmissionRequest) =>
  request({
    url: '/MCBHS300/find',
    method: 'post',
    data: param,
})

export const updateAutoSubmissionRequestSortRequest = (param:AutoSubReqResultDto[]) =>
  request({
    url: '/MCBHS300/displayOrder',
    method: 'post',
    data: param,
})