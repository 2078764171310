import { combineReducers } from '@reduxjs/toolkit'

import messageReducer from './messageReducer'
import accountReducer from './accountReducer'
import userReducer from './userReducer'
import extraAccountReducer from './extraAccountReducer'
import errorReducer from './errorReducer'
import recruitmentManagementFlagReducer from './recruitmentManagementFlagReducer'
import selectionFlowReducer from './selectionFlowReducer'
import recruitmentGroupReducer from './recruitmentGroupReducer'
import commonSettingsMenuReducer from './commonSettingsMenuReducer'
import mediaReducer from './mediaReducer'
import signReducer from './signReducer'
import affiliationRecruitmentReducer from './affiliationRecruitmentReducer'
import selectionStatusUpdateReducer from './selectionStatusUpdateReducer'
import attachmentListReducer from './attachmentListReducer'
import interviewReducer from './interviewReducer'
import companyLoginReducer from './companyLoginReducer'
import globalMenuReducer from './globalMenuReducer'
import advancedSearchReducer from './advancedSearchReducer'
import advancedSearchModalReducer from './advancedSearchModalReducer'
import entryDetailReducer from './entryDetailReducer'
import entryListReducer from './entryListReducer'
import advancedSearchJobChangeReducer from './advancedSearchJobChangeReducer'
import itemSpecificationReducer from './itemSpecificationReducer'
import messageSendReducer from './messageSendReducer'
import mcbMessageSendReducer from './mcbMessageSendReducer'
import messageSendConfirmReducer from './messageSendConfirmReducer'
import mcbMessageSendConfirmReducer from './mcbMessageSendConfirmReducer'
import messageSendCompReducer from './messageSendCompReducer'
import mcbMessageSendCompReducer from './mcbMessageSendCompReducer'
import applicantDetailForEmplymentReducer from './applicantDetailForEmplymentReducer'
import commentUpdateReducer from './commentUpdateReducer'
import starEvaluationUpdateReducer from './starEvaluationUpdateReducer'
import notAdoptedNotificationReducer from './notAdoptedNotificationReducer'
import errorReportReducer from './errorReportReducer'
import spamActReportReducer from './spamActReportReducer'
import alertPreferenceSettingReducer from './alertPreferenceSettingReducer'
import actionPreReducer from './actionPreReducer'
import csvTemplateReducer from './csvTemplateReducer'
import tagManagementReducer from './tagManagementReducer'
import messageListReducer from './messageListReducer'
import selectCriteriaManagementReducer from './selectCriteriaManagementReducer'
import selectCriteriaManagementMcbReducer from './selectCriteriaManagementMcbReducer'
import mcbPdfDownloadReducer from './mcbPdfDownloadReducer'
import applicantInfoManagementReducer from './applicantInfoManagementReducer'
import messageDetailReducer from './messageDetailReducer'
// import newsReducer from './newsReducer'
import recruitmentStatusReportReducer from './recruitmentStatusReportReducer'
import progressStagentAlertReducer from './progressStagentAlertReducer'
import commentHistoryReducer from './commentHistoryReducer'
import progressStatusHistory from './progressStatusHistoryReducer'
// 転職March #75621 START
import progressStatusChange from './progressStatusChangeReducer'
// 転職March #75621 END
import entryHistoryReducer from './entryHistoryReducer'
import seminarReducer from './seminarReducer'
import messageTemplateReducer from './messageTemplateReducer'
// MCBリプレース MCBJS020 START
import submissionReqTemplateReducer from './submissionReqTemplateReducer'
// MCBリプレース MCBJS020 END
import mcaxs260Reducer from './changeOthersReducer'
import MCAXS340Reducer from './recruitmentManagementFlagChangeReducer'
import nameCheckListReducer from './nameCheckListReducer'
import csvOutputReducer from './csvOutputReducer'
import entryRouteReducer from './entryRouteReducer'
import aptitudeTestIDIssueReducer from "./aptitudeTestIDIssueReducer";
import entryUploadReducer from './entryUploadReducer';
import applicantListReducer from './applicantListReducer'
import pdfGeneratingReducer from './pdfGeneratingReducer'
import interviewScheduleReducer from './interviewScheduleReducer'
import jobSeekerInfoReducer from './jobSeekerInfoReducer'
import entryReducer from './entryReducer'
import duplicateIdentificationReducer from './duplicateIdentificationReducer'
import redirectReducer from './redirectReducer'
import facePhotoReducer from './facePhotoReducer'
import  myCareerBoxDataLinkReducer from './myCareerBoxDataLinkReducer'
import endReducer from './endReducer'
import urlReducer from './urlReducer'
// MCBリプレース MCBHS090 START
import submissionRequestForcedStopReducer from './submissionRequestForcedStopReducer'
// MCBリプレース MCBHS090 END
// MCBリプレース MCBHS100 START
import submissionRequestForcedStopConfirmReducer from './submissionRequestForcedStopConfirmReducer'
// MCBリプレース MCBHS100 END
import bulkOperationReducer from './bulkOperationReducer'
import submissionRequestDetailedSearchReducer from './submissionRequestDetailedSearchReducer'
import submissionRequestListReducer from './submissionRequestListReducer'
import submissionRequestDetailedSearchModalReducer from './submissionRequestDetailedSearchModalReducer'
import pdfOutputReducer from './pdfOutputReducer'
// MCBリプレース MCBHS010 START
import submissionRequestSendReducer from './submissionRequestSendReducer'
// MCBリプレース MCBHS010 END
import alertMailSettingReducer from './alertMailPreferenceReducer'
import tableUpdateReducer from './tableUpdateReducer'

import submissionRequestSendCompReducer from './submissionRequestSendCompReducer'
// MCBリプレース MCBLS030 MCBLS020 START
import mcbPdfGeneratingReducer from './mcbPdfGeneratingReducer'
// MCBリプレース MCBLS030 MCBLS020 END
import mcbPdfDownloadSagaReducer from './mcbPdfDownloadReducer'
import mcbMessageForcedStopReducer from './mcbMessageForcedStopReducer'
import mcbMessageDeadlineCompReducer from './mcbMessageDeadlineCompReducer'
import submissionRequestSendConfirmReducer from './submissionRequestSendConfirmReducer'
import mcbDeadlineChangeConfirmReducer from './mcbDeadlineChangeConfirmReducer'

// MCBリプレース MCBHS060 START
import deadlineChangeRequestReducer from './deadlineChangeRequestReducer'
// MCBリプレース MCBHS060 END
//MCAZS170 start
import mcbMessageListReducer from './mcbMessageListReducer'
//MCAZS170 End
//MCAZS210 MCBHS050 start
import mcbMessageDetailReducer from './mcbMessageDetailReducer'
import submissionRequestMessageDetailReducer from './submissionRequestMessageDetailReducer'
//MCAZS210 MCBHS050 end
import autoSubmissionRequestReducer from './autoSubmissionRequestReducer'

const rootReducer = combineReducers({
  user: userReducer,
  snackbar: messageReducer,
  account: accountReducer,
  extraAccount: extraAccountReducer,
  error: errorReducer,
  recruitmentManagementFlag: recruitmentManagementFlagReducer,
  selectionFlow: selectionFlowReducer,
  recruitmentGroup: recruitmentGroupReducer,
  commonSettingsMenu: commonSettingsMenuReducer,
  media: mediaReducer,
  sign: signReducer,
  affiliationRecruitment: affiliationRecruitmentReducer,
  selectionStatusUpdate: selectionStatusUpdateReducer,
  attachmentList: attachmentListReducer,
  interview: interviewReducer,
  companyLogin: companyLoginReducer,
  globalMenu: globalMenuReducer,
  advancedSearch: advancedSearchReducer,
  advancedSearchModal: advancedSearchModalReducer,
  entryDetail: entryDetailReducer,
  entryList: entryListReducer,
  advancedSearchJobChange: advancedSearchJobChangeReducer,
  itemSpecification: itemSpecificationReducer,
  messageSend: messageSendReducer,
  mcbMessageSend: mcbMessageSendReducer,
  messageSendConfirm: messageSendConfirmReducer,
  mcbMessageSendConfirm: mcbMessageSendConfirmReducer,
  messageSendComp: messageSendCompReducer,
  mcbMessageSendComp: mcbMessageSendCompReducer,
  mcbPdfDownload:mcbPdfDownloadReducer,
  applicantDetailForEmplyment: applicantDetailForEmplymentReducer,
  commentUpdate: commentUpdateReducer,
  starEvaluationUpdate: starEvaluationUpdateReducer,
  notAdoptedNotification: notAdoptedNotificationReducer,
  errorReport: errorReportReducer,
  spamActReport: spamActReportReducer,
  alertPreferenceSetting: alertPreferenceSettingReducer,
  // 次期9月対応 #63073 start
  alertMailSetting:alertMailSettingReducer,
  // 次期9月対応 #63073 end
  actionPre: actionPreReducer,
  csvTemplateType: csvTemplateReducer,
  tagManagement: tagManagementReducer,
  messageList: messageListReducer,
  selectCriteriaManagement: selectCriteriaManagementReducer,
  selectCriteriaManagementMcb: selectCriteriaManagementMcbReducer,
  applicantInfoManagement: applicantInfoManagementReducer,
  messageDetail: messageDetailReducer,
  // newsMenu: newsReducer,
  recruitmentStatusReport: recruitmentStatusReportReducer,
  progressStagentAlert: progressStagentAlertReducer,
  commentHistory: commentHistoryReducer,
  progressStatusHistory: progressStatusHistory,
  // 転職March #75621 START
  progressStatusChange: progressStatusChange,
  // 転職March #75621 END
  entryHistory: entryHistoryReducer,
  seminar:seminarReducer,
  messageTemplate:messageTemplateReducer,
  // MCBリプレース MCBJS020 START
  submissionReqTemplate:submissionReqTemplateReducer,
  // MCBリプレース MCBJS020 END
  mcaxs260Reducer:mcaxs260Reducer,
  mcaxs340Reducer:MCAXS340Reducer,
  applicantList:applicantListReducer,
  nameCheckList: nameCheckListReducer,
  csvOutput:csvOutputReducer,
  entryRoute: entryRouteReducer,
  aptitudeTestIDIssue: aptitudeTestIDIssueReducer,
  entryUpload:entryUploadReducer,
  pdfGenerating: pdfGeneratingReducer,
  pdfOutput: pdfOutputReducer,
  interviewSchedule: interviewScheduleReducer,
  jobSeekerInfo: jobSeekerInfoReducer,
  entry: entryReducer,
  duplicateIdentification: duplicateIdentificationReducer,
  redirect: redirectReducer,
  facePhoto:facePhotoReducer,
  myCareerBoxInfo: myCareerBoxDataLinkReducer,
  end: endReducer,
  url: urlReducer,
  // MCBリプレース MCBHS090 START
  submissionRequestForcedStop: submissionRequestForcedStopReducer,
  // MCBリプレース MCBHS090 END
  // MCBリプレース MCBHS100 START
  submissionRequestForcedStopConfirm: submissionRequestForcedStopConfirmReducer,
  // MCBリプレース MCBHS100 END
  // MCBリプレース MCBHS040 START
  bulkOperation:bulkOperationReducer,
  submissionRequestDetailedSearch: submissionRequestDetailedSearchReducer,
  submissionRequestList:submissionRequestListReducer,
  submissionRequestDetailedSearchModal:submissionRequestDetailedSearchModalReducer,
  // submissionStatusList:bulkOperationReducer,
  // submissionContentIdList:bulkOperationReducer,
  // MCBリプレース MCBHS040 END
// MCBリプレース MCBHS030 START
submissionRequestSendComp: submissionRequestSendCompReducer,
// MCBリプレース MCBHS030 END
  // MCBリプレース MCBLS030 MCBLS020 START
  mcbPdfGenerating: mcbPdfGeneratingReducer,
  // MCBリプレース MCBLS030 MCBLS020 END
  // MCBリプレース MCBLS010 START
  PdfDownloadRequestSearch: mcbPdfDownloadSagaReducer,
  // MCBリプレース MCBLS010 END
  //MCBHS110
  mcbMessageForcedStop: mcbMessageForcedStopReducer,
  //MCBHS080
  mcbMessageDeadlineComp: mcbMessageDeadlineCompReducer,
  //MCBHS070 START
  mcbDeadlineChangeConfirm: mcbDeadlineChangeConfirmReducer,
  //MCBHS070 END
 // MCBリプレース MCBHS010 START
  submissionRequestSend: submissionRequestSendReducer,
  // MCBリプレース MCBHS010 END
  // MCBリプレース MCBHS020 START
  submissionRequestSendConfirm: submissionRequestSendConfirmReducer,
  // MCBリプレース MCBHS020 END

  // MCBリプレース MCBHS060 START
  deadlineChangeRequest:deadlineChangeRequestReducer,
  // MCBリプレース MCBHS060 END
  //MCBリプレース MCAZS170 start
  mcbMessageList:mcbMessageListReducer, 
  //MCBリプレース MCAZS170 end
  //MCBリプレース MCAZS210 start
  mcbMessageDetail: mcbMessageDetailReducer,
  //MCBリプレース MCAZS210 end
  //MCBリプレース MCABH050 start
  submissionRequestMessageDetail: submissionRequestMessageDetailReducer,
  //MCBリプレース MCABH050 end
  tableUpdate: tableUpdateReducer,
  // 27P #95408 start
  autoSubmissionRequest: autoSubmissionRequestReducer,
  // 27P #95408 end
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
