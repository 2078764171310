export interface MCBHS350ReturnDisplayOutDto {
  countTemp: number,
  maxTempCount: number,
  templateNameOption: {
    value: string,
    label: string,
    sysVersionNumber: string
  },
  fileManagementIds: string[]
  attachmentNames: string[]
  autoSubmissionRequestSettingId: number
  sysVersionNumber: number
}

export const initFinishDisplayValues: MCBHS350ReturnDisplayOutDto = {
  countTemp: 0,
  maxTempCount: 0,
  templateNameOption: {
    value: '',
    label: '',
    sysVersionNumber: ''
  },
  fileManagementIds: [],
  attachmentNames: [],
  autoSubmissionRequestSettingId: 0,
  sysVersionNumber: 0,
}