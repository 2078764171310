import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'
import { ExceptForcedStopedList } from 'componentsHsc/AdvancedSearch/SubmissionRequest'

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
  myChange: Function
  formName?: string
  target?: string
  targetIndex?: number
  targetIndex2?: number
  selectOptionList?: Option[]
}

const UncheckableToggle = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
  targetIndex,
  targetIndex2,
  target,
  selectOptionList,
}: Props) => {
  const classes = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'
  const handleChange = (e: Option) => {

    if (e.value !== undefined) {
      switch (target) {
        case 'mcbUsageObj':
        case 'submissionStatusConditionsObj':
          form.setFieldValue('filingDateFrom', null)
          form.setFieldValue('filingDateTo', null)
          if (handleRadioCheck(e.value)) {
            form.setFieldValue(target, null)
          } else {
            const option: Option = {
              value: e.value,
              label: e.label,
            }
            form.setFieldValue(target, option)
          }
          break
        case 'submissionDeadlineConditionsObj':
          form.setFieldValue('submissionDeadlineFrom', null)
          form.setFieldValue('submissionDeadlineTo', null)
          if (handleRadioCheck(e.value)) {
            form.setFieldValue(target, null)
          } else {
            const option: Option = {
              value: e.value,
              label: e.label,
            }
            form.setFieldValue(target, option)
          }
          break
        case 'deliveryStatusObj':
          //26KH #89293 start
          form.setFieldValue('exceptForcedStopedObj', null)
          //26KH #89293 end
          form.setFieldValue('sendDateFrom', null)
          form.setFieldValue('sendDateTo', null)
          // MCBリプレース #8535 START
          form.setFieldValue('deadlineTypeObj', null)
          // MCBリプレース #8535 END
          form.setFieldValue('deadlineDateFrom', null)
          form.setFieldValue('deadlineDateTo', null)
          form.setFieldValue('submitStatusObj', null)
          form.setFieldValue('submitDateFrom', null)
          form.setFieldValue('submitDateTo', null)
          if (handleRadioCheck(e.value)) {
            form.setFieldValue(target, null)
            form.setFieldValue('exceptForcedStopedObj', null)
          } else {
            const option: Option = {
              value: e.value,
              label: e.label,
            }
            form.setFieldValue(target, option)
            if (e.value === '1') {
              form.setFieldValue('exceptForcedStopedObj', ExceptForcedStopedList)
            } else {
              form.setFieldValue('exceptForcedStopedObj', null)
            }
          }
          break
        case 'deadlineTypeObj':
          form.setFieldValue('deadlineDateFrom', null)
          form.setFieldValue('deadlineDateTo', null)
          if (!disabled) {
          if (handleRadioCheck(e.value)) {
            form.setFieldValue(target, null)
          } else {
            const option: Option = {
              value: e.value,
              label: e.label,
            }
            form.setFieldValue(target, option)
          }
          }
          break
        case 'submitStatusObj':
          form.setFieldValue('submitDateFrom', null)
          form.setFieldValue('submitDateTo', null)
          if (!disabled) {
            if (handleRadioCheck(e.value)) {
              form.setFieldValue(target, null)
            } else {
              const option: Option = {
                value: e.value,
                label: e.label,
              }
              form.setFieldValue(target, option)
            }
          }
          break
        default:
          form.setFieldValue(name, e.value)
          break
      }
    }
  }

  const handleRadioCheck = (value: string) => {
    if (value !== undefined) {
      let result = undefined
      switch (target) {
        case 'mcbUsageObj':
          const mcbUsageObj = form.values.mcbUsageObj
          const mcbUsageObjValue = ((mcbUsageObj === null || mcbUsageObj === undefined) ? undefined : mcbUsageObj.value)
          result = mcbUsageObjValue === value
          break
        case 'submissionDeadlineConditionsObj':
          const submissionDeadlineConditionsObj = form.values.submissionDeadlineConditionsObj
          const submissionDeadlineConditionsObjValue = ((submissionDeadlineConditionsObj === null || submissionDeadlineConditionsObj === undefined) ? undefined : submissionDeadlineConditionsObj.value)
          result = submissionDeadlineConditionsObjValue == value
          break
        case 'submissionStatusConditionsObj':
          const submissionStatusConditionsObj = form.values.submissionStatusConditionsObj
          const submissionStatusConditionsObjValue = ((submissionStatusConditionsObj === null || submissionStatusConditionsObj === undefined) ? undefined : submissionStatusConditionsObj.value)
          result = submissionStatusConditionsObjValue === value
          break
        case 'deadlineTypeObj':
          const deadlineTypeObj = form.values.deadlineTypeObj
          const deadlineTypeObjValue = ((deadlineTypeObj === null || deadlineTypeObj === undefined) ? undefined : deadlineTypeObj.value)
          result = deadlineTypeObjValue == value
          break
        case 'deliveryStatusObj':
          const deliveryStatusObj = form.values.deliveryStatusObj
          const deliveryStatusObjValue = ((deliveryStatusObj === null || deliveryStatusObj === undefined) ? undefined : deliveryStatusObj.value)
          result = deliveryStatusObjValue === value
          break
        case 'submitStatusObj':
          const submitStatusObj = form.values.submitStatusObj
          const submitStatusObjValue = ((submitStatusObj === null || submitStatusObj === undefined) ? undefined : submitStatusObj.value)
          result = submitStatusObjValue === value
          break
        default:
          break
      }
      return result
    }
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <RadioGroup row={row} value={value}>
        {optionList.map(i => (
          <div onClick={() => handleChange(i)} style={{ cursor: 'pointer', marginRight: '22px', marginLeft: '-11px', zIndex: 0 }}>
            <Radio
              color='primary'
              value={i.value}
              disabled={disabled}
              checked={handleRadioCheck(i.value)}
              name={i.label}
            />
            &nbsp;{i.label}
          </div>
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default UncheckableToggle
