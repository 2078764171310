import React, { useCallback, useEffect, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import { useLocation } from "react-router-dom";

import ErrorBoundary from 'pages/ErrorBoundary'
import { publicRouteList, privateRouteList  } from 'routes'
import { routeList } from 'routes/routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import {
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  AppBar,
  Typography,
  Toolbar,
  SnackbarCloseReason,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
// 26KH #116733 START
import { getGtmId, getDataLayerEvent } from 'reducers/companyLoginReducer'
// 26KH #116733 END
import { closeMessage } from 'reducers/messageReducer'
import { sendError } from 'reducers/errorReducer'
import { signoutSuccess, setTargetYearErrorFlag, setTargetServiceErrorFlag, serviceModeChange, setErrorMessageForOKControl, getDataLayerInfo } from 'reducers/globalMenuReducer'
import history from 'utils/history';
import { getYearChangeMessage2, getYearChangeUrl2, getYearChangeUrl, getYearChangeUrl3, getMarchModeChangeMessage2, caseOfToEntryListUrl } from 'layouts/Dashboard/handleTopBarUrl';
import { listener as listenerMCACS020} from 'pages/MCACS020/formConfig';
import { listener as listenerMCAUS010} from 'pages/MCAUS010/formConfig';
import { listener as listenerMCAXS191} from 'pages/MCAXS191/formConfig';
import { listener as listenerMCAXS201} from 'pages/MCAXS201/formConfig';
import { listener as listenerMCAXS211} from 'pages/MCAXS211/formConfig';
import { listener as listenerMCAXS220} from 'pages/MCAXS220/formConfig';
import { listener as listenerMCAGS010} from 'pages/MCAGS010/formConfig';
import { JudgeUrl } from 'layouts/Dashboard';
import { magiContants as contants, magiContants } from 'utils/contants';
import TagManager from 'react-gtm-module';
import { getTargetYearFromToken } from 'utils/yearCheckUtil';
import { getMessage } from 'common/messageUtil';
import { downloadStatus, updateFlag } from 'reducers/mcbPdfDownloadReducer';
import { isEmpty } from 'common/generalUtil'

const useStyles = makeStyles({
  modal: {
    '& .MuiDialog-paper': {
      minWidth: '400px',
    },
  },
  lineBreak: {
    whiteSpace: 'pre-line'
  }
})

const App: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  // 26KH #116733 START
  const { token, gtmId, dataLayerEvent } = useSelector((state: RootState) => state.companyLogin)
  // 26KH #116733 END
  // 転職March #75621 START
  const { permissions, errorMessageForOKControl } = useSelector((state: RootState) => state.globalMenu)
  // 転職March #75621 END
  const { message, type } = useSelector((state: RootState) => state.snackbar)
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  const { errorMessage, errorStack } = useSelector(
    (state: RootState) => state.error
  )

  const snackbarOpen = Boolean(message) && type === 'snackbar'
  const modalOpen = Boolean(message) && type === 'modal'
  const modalOpenWindowClose = Boolean(message) && type === 'modalWindowClose'
  const fullpageOpen = Boolean(message) && type === 'fullpage'
  const modalLogout = Boolean(message) && type === 'modalLogout'

  const dispatch = useDispatch()
  // 26KH #80783 START
  const { recruitmentManagementDivision, companyAccountId, syusyokuNaviCompanyId, companyId, targetYear } = useSelector((state: RootState) => state.globalMenu)
  // 26KH #80783 END

  // 27P #89230 start
  const downloadStatusValue = useSelector(
    (state: RootState) => state.mcbPdfDownload.downloadStatus
  )
  const pdfDownloadAlertMilliseconds = useSelector((state: RootState) => state.mcbPdfDownload.pdfDownloadAlertMilliseconds)
  const [snackbarOn, setSnackbarOn] = useState(false)

  const closeSnackbar = () => {
    dispatch(downloadStatus(''))
    if (!isEmpty(downloadStatusValue)) {
      dispatch(updateFlag(downloadStatusValue))
      localStorage.setItem('pdfDownloadStatus', '')
    }
    setSnackbarOn(false)
  }

  const closeSnackbarByTimeout = (_event: any, reason: SnackbarCloseReason) => {
    if (reason == 'clickaway') {
      return
    }
    dispatch(downloadStatus(''))
    if (!isEmpty(downloadStatusValue)) {
      dispatch(updateFlag(downloadStatusValue))
      localStorage.setItem('pdfDownloadStatus', '')
    }
    setSnackbarOn(false)
  }

  const [snackbarContent, setSnackbarContent] = useState('')

  // フォーカス取得
  const [focusFlg, setFocusFlg] = useState(true)
  window.onfocus = () => {
    setFocusFlg(true)
  }
  window.onblur = () => {
    setFocusFlg(false)
  }

  // 文言判定
  useEffect(() => {
    const pdfDownloadStatus = localStorage.getItem('pdfDownloadStatus')
    if (focusFlg && downloadStatusValue && !isEmpty(pdfDownloadStatus)) {
      switch(downloadStatusValue){
        case '1':
          setSnackbarContent(getMessage(magiContants.DOWNLOAD_DISPLAY_001));
          break;
        case '9':
          setSnackbarContent(getMessage(magiContants.DOWNLOAD_DISPLAY_002));
          break;
        case '3':
          setSnackbarContent(getMessage(magiContants.DOWNLOAD_DISPLAY_003));
          break;
        default:
          break;
      }
      setSnackbarOn(true);
    } else {
      setSnackbarOn(false);
    }
  }, [focusFlg, downloadStatusValue]);
  // 27P #89230 end

  // 転職March #75621 START
  // OKボタン機能カスタム用モーダル
  const marchModeChildWindowURL = [
    routeList.entryDetail,
    routeList.pdfGenerating,
    routeList.pdfDownload,
  ]
  const modalOpenForOKControl = Boolean(errorMessageForOKControl)
  const handleOK = useCallback(() => {
    if (errorMessageForOKControl == magiContants.MESSAGECODE_MCAAS010_004) {
      if (marchModeChildWindowURL.findIndex(i =>
        history.location.pathname && history.location.pathname.indexOf(i) >= 0
      ) >= 0) {
        dispatch(setErrorMessageForOKControl(''))
      } else {
        dispatch(serviceModeChange({
          targetService: "0",
          specailCaseType: caseOfToEntryListUrl.indexOf(history.location.pathname) >= 0 ? '1' : '0',
        }))
      }
    }
  }, [errorMessageForOKControl])
  // 転職March #75621 END

  const realUrl = useSelector(
    (state: RootState) => state.url
  )

  const handleClose = (message: string) => () => {
    dispatch(closeMessage())
    if (message.includes("以下いずれかの事象が発生したため、ログイン状態が無効になりました。")) {
      dispatch(signoutSuccess(realUrl.LOGIN_URL))
    }

    if ((window.location.pathname).indexOf('MCAZS030') != -1) {
      const dom = document.getElementById('mcazs030Reservation')
      if (dom) {
        dom.click()
      }
    }
    // 次期開発5月向#58931 start
    if ((window.location.pathname).indexOf('MCAZS020') != -1) {
      const dom = document.getElementById('mcazs020Reservation')
       if (dom && message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。")) {
        dom.click()
      }
    }
    if ((window.location.pathname).indexOf('MCAZS010') != -1) {
      const dom = document.getElementById('mcazs010Reservation')
       if (dom && message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。")) {
        dom.click()
      }
    }
    if ((window.location.pathname).indexOf('MCAZS040') != -1) {
      const dom = document.getElementById('mcazs040Reservation')
       if (dom && message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。")) {
        dom.click()
      }
    }
    if ((window.location.pathname).indexOf('MCAZS050') != -1) {
      const dom = document.getElementById('mcazs050Reservation')
       if (dom && message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。")) {
        dom.click()
      }
    }
    // MCB リプレース対応 バグ #7991対応 start
    if ((window.location.pathname).indexOf('MCAZS170') != -1) {
      const dom = document.getElementById('mcazs170Reservation')
       if (dom && (message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。") || message.includes("送信中のメッセージが選択されているためゴミ箱に移動できません。")|| message.includes("メッセージ送信に伴う処理が完了していないメッセージが含まれています。\n恐れ入りますが、時間をおいて再度実施してください。"))) {
        dom.click()
      }
    }
    if ((window.location.pathname).indexOf('MCAZS180') != -1) {
      const dom = document.getElementById('mcazs180Reservation')
      if (dom) {
        dom.click()
      }
    }
    // MCB リプレース対応 バグ #7991対応 end
    if ((window.location.pathname).indexOf('MCAZS190') != -1) {
      const dom = document.getElementById('mcazs190Reservation')
       if (dom && message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。")) {
        dom.click()
      }
    }
    if ((window.location.pathname).indexOf('MCAZS200') != -1) {
      const dom = document.getElementById('mcazs200Reservation')
      if (dom && message.includes("閲覧権限のない応募者へのメッセージが含まれるため、操作できません。")) {
        dom.click()
      }
    }
    // 次期開発5月向#58931 end
    if (message.includes("アクセスのURLが不正です。")) {
      history.push(routeList.login)
    }
  }
  const handleCloseWindowClose = () => {
    dispatch(closeMessage())
    //メールでシェアされた応募者またはエントリーURLで応募者詳細の場合、(Windows.open以外)
    if (routeList.applicantDetailForEmplyment.includes(window.location.pathname) && window.location.search) {
      history.replace(routeList.recruitmentStatusReportTab)
    } else if (routeList.entryDetail.includes(window.location.pathname) && window.location.search) {
      history.replace(routeList.recruitmentStatusReportTab)
    } else {
      window.close()
    }
  }

  useEffect(() => {
    dispatch(getGtmId())
    if (errorMessage || errorStack) {
      dispatch(sendError({ errorMessage, errorStack }))
    }
  }, [])

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [gtmId])
  // 26KH #116733 START
  useEffect(() => {
    dispatch(getDataLayerEvent())
    if (errorMessage || errorStack){
      dispatch(sendError({ errorMessage, errorStack }))
    }
  }, [])
  // 26KH #116733 END
  // 26KH #80783 START
  useEffect(() => {
    dispatch(getDataLayerInfo())
    if (errorMessage || errorStack){
      dispatch(sendError({ errorMessage, errorStack }))
    }
    const head = document.getElementsByTagName('head')[0] as HTMLElement
    const datalayer = document.createElement('script')
    // ログインしていない状態（ログイン前、ログアウト後）の場合
    if( companyAccountId === "" || syusyokuNaviCompanyId === "" || companyId === "" || targetYear === ""){
    datalayer.innerHTML = 
      `window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        user_id: "",
        corpId: "",
        miws_id: "",
        display_year: "",
        event: ""
      });`
        head.prepend(datalayer)
        return () => {
          document.head.removeChild(datalayer)
        }
    // 採用管理区分 = "1"(就職) の場合
    } else if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1) {
      datalayer.innerHTML =
      `window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        user_id: ${companyAccountId},
        corpId: "${syusyokuNaviCompanyId}",
        miws_id: ${companyId},
        display_year: "${targetYear.substring(4,8)}",
        event: "${dataLayerEvent}"
      });`
      head.prepend(datalayer)
      return () => {
        document.head.removeChild(datalayer)
      }
    }
  }, [companyAccountId, syusyokuNaviCompanyId, companyId, targetYear, dataLayerEvent])
  // 26KH #80783 END

  const [styleType, setStyleType] = useState("")
  useEffect(() => {
    if (location.pathname === routeList.entryDetail
        || location.pathname === routeList.entryList
        || location.pathname === routeList.applicantDetailForJobChange
        || location.pathname === routeList.applicantDetailForEmplyment
        || location.pathname === routeList.applicantList
		// MCBリプレース MCBHS040 START
        || location.pathname === routeList.submissionRequestManagement
		// MCBリプレース MCBHS040 END
    ) {
      setStyleType("applicant-frame")
    } else {
      setStyleType("")
    }
  }, [location])

  // 年度対応 start
  const onClickOfYearChangeError = async () => {
    dispatch(setTargetYearErrorFlag(false))
    // 27P #95408 start
    let year = getTargetYearFromToken().year
    const url = getYearChangeUrl2(history.location.pathname, year)
    // MCB ロット３　start
    const urlMcb = getYearChangeUrl3(history.location.pathname, year)
    // MCB ロット３　end
    // 27P #95408 end
    // 年度切替成功後に画面が応募者一覧またはメッセージ一覧の場合history内の画面間用パラメターをクリア
    if (routeList.applicantList == history.location.pathname ||
      routeList.messageInBox == history.location.pathname) {
      history.replace({ state: undefined })
    }
   // MCB ロット３　start
    //直リンクからの遷移で自動的に切替／別画面で年度切替
    if (url && year<contants.MCB_YEAR_2025) {
      await forbiddenBeforeunload()
      window.location.href = url
    } else if(urlMcb && year >= contants.MCB_YEAR_2025){
      await forbiddenBeforeunload()
      window.location.href = urlMcb 
    // MCB ロット３　end
    } else if ((window.opener && window.opener !== window) || JudgeUrl() || routeList.newLoginId == history.location.pathname
        || routeList.applicantDetailForEmplyment == history.location.pathname) {
      // 動作なし
    } else {
      window.location.reload()
    }
  }

  const forbiddenBeforeunload = () => {

    if (routeList.password == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCACS020)
    } else if (routeList.jobSeekerNameInput == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS191)
    } else if (routeList.jobSeekerLogIn == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS201)
    } else if (routeList.jobSeekerInfoInsertConfirm == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS211)
    } else if (routeList.errorReport == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAUS010)
    // 転職March #75621 START
    } else if (routeList.entryInsEdit == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS220)
    } else if (routeList.notAdoptedNotification == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAGS010)
    }
    // 転職March #75621 END
  }
  // 年度対応 end

  // 転職March #75621 START
  const onClickOfModeChangeError = async () => {
    dispatch(setTargetServiceErrorFlag(false))
    const specailCaseType = caseOfToEntryListUrl.indexOf(history.location.pathname) >= 0 ? '1' : '0'
    // 年度切替成功後に画面がエントリー一覧またはメッセージ一覧の場合history内の画面間用パラメターをクリア
    if (routeList.entryList == history.location.pathname ||
      routeList.messageInBox == history.location.pathname) {
      history.replace({ state: undefined })
    }
    //直リンクからの遷移で自動的に切替／別画面で年度切替
    if (specailCaseType == '1') {
      await forbiddenBeforeunload()
      window.location.href = routeList.entryList
    } else if ((window.opener && window.opener !== window) || JudgeUrl() || routeList.newLoginId == history.location.pathname
      || routeList.entryDetail == history.location.pathname || routeList.applicantDetailForJobChange == history.location.pathname) {
      // 動作なし
    } else {
      // 転職Marchはすべてエントリー一覧へ
      window.location.href = routeList.entryList
    }
  }
  // 転職March #75621 END

  return (
    <ErrorBoundary>
      <div className={token ? styleType : ""} style={{display: 'inline'}} >
        {renderRoutes(token ? privateRouteList(permissions) : publicRouteList)}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose(message)}
        message={<span>{message}</span>}
        action={
          <IconButton color='inherit' onClick={handleClose(message)}>
            <CloseIcon />
          </IconButton>
        }
      />

      {/*  27P #89230 start */}
      <Snackbar
        open={!snackbarOpen && snackbarOn}
        onClose={closeSnackbarByTimeout}
        autoHideDuration={pdfDownloadAlertMilliseconds}
        message={
          <span
            dangerouslySetInnerHTML={{
              __html: snackbarContent,
            }}
          />
        }
        action={
          <IconButton color='inherit' onClick={closeSnackbar}>
            <CloseIcon />
          </IconButton>
        }
      />
      {/*  27P #89230 end */}
      

      <Dialog 
        open={modalOpen} 
        onClose={handleClose(message)} 
        className={classes.modal} 
        disableBackdropClick
        transitionDuration={0}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.lineBreak}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose(message)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        fullScreen 
        open={fullpageOpen} 
        onClose={handleClose(message)}
        disableBackdropClick
        transitionDuration={0}>
        <AppBar position='relative'>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose(message)}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6'>エラー</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose(message)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={modalOpenWindowClose} 
        onClose={handleCloseWindowClose} 
        className={classes.modal}
        disableBackdropClick
        transitionDuration={0}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWindowClose}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={modalLogout} 
        onClose={handleClose(message)} 
        className={classes.modal} 
        disableBackdropClick
        transitionDuration={0}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose(message)}>OK</Button>
        </DialogActions>
      </Dialog>

      {/** 年度対応 start */}
      <Dialog 
        open={globalMenu.targetYearErrorFlag} 
        disableBackdropClick
        style={{ zIndex: 1301 }}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.lineBreak}>
              {getYearChangeMessage2(history.location.pathname)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickOfYearChangeError}>OK</Button>
        </DialogActions>
      </Dialog>
      {/** 年度対応 end */}

      {/** 転職March #75621 START */}
      <Dialog
        open={globalMenu.targetServiceErrorFlag}
        disableBackdropClick
        style={{ zIndex: 1301 }}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.lineBreak}>
            {getMarchModeChangeMessage2(history.location.pathname)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickOfModeChangeError}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalOpenForOKControl}
        disableBackdropClick
        transitionDuration={0}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.lineBreak}>
            {marchModeChildWindowURL.findIndex(i =>
              history.location.pathname && history.location.pathname.indexOf(i) >= 0
            ) < 0 ? getMessage(errorMessageForOKControl) : getMessage(magiContants.MESSAGECODE_MCAAS010_005)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK}>OK</Button>
        </DialogActions>
      </Dialog>
      {/** 転職March #75621 END */}

    </ErrorBoundary>
  )
}

export default App
