/*
 * 自動提出リクエスト登録画面、データ表示用Obj
 */
export interface MCBHS310ReturnDisplayOutDto {
  autoSubmissionRequestSettingId: string // 自動提出リクエスト設定ID
  autoSubmissionRequestName: string // 自動提出リクエスト登録名
  destinationSelection: string	// 送信先区分
  entryBoxList: MCBHS310EntryBoxInfo[] // エントリーボックス情報
  seminarList: MCBHS310SeminarInfo[] // セミナー情報
  entryBoxIds: string[] // 対象者設定(エントリーボックスID)
  seminarIds: string[] // 対象者設定(セミナーID)
  targetCriteria: string // 対象者設定(自動提出リクエスト条件)
  useSetting: string // 利用設定フラグ
  useSettingStartDate: string // 利用設定_利用開始日時
  useSettingEndDate: string // 利用設定_利用終了日時
  submissionRequestTemplateInfoList: MCBHS310SubmissionRequestTemplateInfo[] // 提出リクエストテンプレート情報
  submissionRequestTemplateId: string // 提出リクエストテンプレート選択ID
  submissionRequestTemplateName: string // 提出リクエストテンプレート選択名
  submissionsContentList: MCBHS310SubmissionsContentInfo[] // 提出物内容リスト
  submissionsSelectId: string // 提出物内容選択ID
  submissionsName: string // 提出物内容（企業管理用）
  submissionsNameStudent: string // 提出物内容（学生画面表示用）
  submissionsNames: string // 提出物
  submissionsEntryFlg: string // 提出物エントリシートフラグ
  submissionsResumeFlg: string // 提出物履歴書フラグ
  submissionsResearchFlg: string // 提出物研究概要書フラグ
  submissionsOriginalFlg: string // 提出物オリジナル設問フラグ
  submissionsFileFlg: string // 提出物ファイル提出フラグ
  limitDateDetermineFlag: string // 提出締切_種別
  determineDateTime: string // 提出締切日指定日時
  requestDayTimeLaterDay: string // 依頼日から○日後
  requestDayTimeToTime: string // 依頼日から△:00まで
  anytimeReceptionEndDateTime: string // 随時受付終了日時
  reRequestReceptFlag: string // 再提出受付フラグ
  senderCompanyName: string // 送信元会社名の指定
  senderMailAddress: string // 返信先メールアドレス
  referenceContentList: MCBHS310ReferenceContentInfo[] // お問い合わせ先リスト
  referenceContentId: string // お問い合わせ先選択ID
  referenceContentName: string // お問い合わせ先選択名
  referenceContentCompanyName: string // お問い合わせ先企業名
  referenceContentDepartmentNameAndManagerName: string // お問い合わせ先部署名／担当者氏名
  referenceContentTelephoneNumber: string // お問い合わせ先電話番号
  referenceContentMailAddress: string // お問い合わせ先メールアドレス
  signatureList: MCBHS310SignatureInfo[] // 署名リスト
  requestSubject: string // 件名
  requestText: string // 本文
  attachmentFileList: AttachmentFileInfo[] // 添付ファイル情報
  homePageTitle1: string // ホームページタイトル1
  homePageUrl1: string // ホームページURL1
  homePageTitle2: string // ホームページタイトル2
  homePageUrl2: string // ホームページURL2
  homePageTitle3: string // ホームページタイトル3
  homePageUrl3: string // ホームページURL3
  thanksMessageSetFlag: string // サンクスメッセージ設定フラグ
  thanksMessageTemplateList: MCBHS310ThanksMessageTemplateInfo[] // サンクスメールテンプレート内容リスト
  thanksMessageTemplateId: string // サンクスメールテンプレートID
  thanksMessageTemplateName: string // サンクスメールテンプレート名
  thanksSubject: string // （サンクス）件名
  thanksText: string // （サンクス）本文
  privacyPolicyContentList: MCBHS310PrivacyPolicyContentInfo[] // 個人情報の取り扱い内容リスト
  personalDataHandlingId: string // 個人情報の取り扱い選択ID
  personalDataHandlingName: string // 個人情報の取り扱い名
  exampleLink: string // 文例リンク
  submissionsLink: string // 提出物内容の作成・確認はこちらリンク
  referenceLink: string // お問い合わせ先の作成・確認はこちらリンク
  requestExampleLink: string // 提出リクエストメールの文例集を見るリンク
  thanksMailTemplateLink: string // サンクスメッセージテンプレートの作成・確認はこちらリンク
  thanksMailExampleLink: string // サンクスメッセージの文例集を見るリンク
  personalDataHandlingLink: string // 個人情報の取り扱いの作成・確認はこちらリンク
  authToken: string // MCB認証トークン
  annual: string // 年度
  annualList: string // 対象年度リスト
  annualCs: string // チェックサム
  forbiddenWordsList: string[] // 禁止語リスト
  autoSubmissionRequestUseStartTime: AutoSubmissionRequestUseStartTimeDto[] // 自動提出リクエスト不可期間
  isManagementOffice: boolean // 運営事務局判定
}
/*
 * 自動提出リクエスト条件指定のエントリーボックスデータ
 */
export interface MCBHS310EntryBoxInfo {
  entryBoxId: string
  coordinationFormerEntryBoxIdentificationKey: string
  entryBoxName: string
  displayStartDate: string
  displayEndDate: string
  selectFlag: boolean
  disabledFlag: boolean
}
/*
 * 自動提出リクエスト条件指定のセミナーデータ
 */
export interface MCBHS310SeminarInfo {
  seminarId: string
  coordinationFormerSeminarIdentificationKey: string
  seminarName: string
  publicFlag: string
  displayStartDate: string
  displayEndDate: string
  selectFlag: boolean
  disabledFlag: boolean
}
/*
 * 提出リクエストテンプレートデータ
 */
export interface MCBHS310SubmissionRequestTemplateInfo {
  submissionRequestTemplateSettingId: string // 提出リクエストテンプレート設定ID
  templateName: string // テンプレート名
  submissionContentId: string // 提出物内容ID
  submissionContentName: string // 提出物内容名
  submissionContentNameStudent: string // 提出物内容名（学生画面表示用）
  submissionEntrySheetFlag: string// 提出物エントリシートフラグ
  submissionResumeFlag: string // 提出物履歴書フラグ
  submissionResearchSummaryFlag: string // 提出物研究概要書フラグ
  submissionOriginalQuestionFlag: string // 提出物オリジナル設問フラグ
  submissionFileSubmissionFlag: string // 提出物ファイル提出フラグ
  submissionDeadlineKind: string // 提出締切_種別
  submissionDeadlineDay: string // 提出締切_日
  submissionDeadlineHour: string // 提出締切_時
  resubmitReceptionFlag: string // 再提出受付フラグ
  senderCompanyName: string // 送信元会社名の指定
  senderMailAddress: string // 返信先メールアドレス
  contactId: string // お問い合わせ先選択ID
  contactName: string // お問い合わせ先名
  contactCompanyName: string // お問い合わせ先企業名
  contactDepartmentNameAndManagerName: string // お問い合わせ先部署名／担当者氏名
  contactTelephoneNumber: string // お問い合わせ先電話番号
  contactMailAddress: string // お問い合わせ先メールアドレス
  subject: string // 件名
  body: string // 本文
  attachmentFileList: AttachmentFileInfo[] // 添付ファイル情報
  homePageTitle1: string // ホームページタイトル1
  homePageUrl1: string // ホームページURL1
  homePageTitle2: string // ホームページタイトル2
  homePageUrl2: string // ホームページURL2
  homePageTitle3: string // ホームページタイトル3
  homePageUrl3: string // ホームページURL3
  thanksMessageSettingFlag: string // サンクスメッセージ設定フラグ
  thanksMessageTemplateId: string // サンクスメールテンプレートID
  thanksMessageTemplateName: string // サンクスメールテンプレート名
  thanksSubject: string // （サンクス）件名
  thanksBody: string // （サンクス）本文
  privacyPolicyId: string // 個人情報の取り扱いID
  privacyPolicyName: string // 個人情報の取り扱い名
  sequence: number // 連番
  sysVersionNumber: string // sysバージョン番号
}
/*
 * 提出物内容データ
 */
export interface MCBHS310SubmissionsContentInfo {
  submissionsContentId: number // 提出物内容ID
  submissionsNameForCompany: string // 提出物内容名（企業管理用）
  submissionsNameForStudent: string // 提出物内容名（学生）
  entrySheetResumeSubmissionFlg: boolean // エントリーシート/履歴書提出フラグ
  entrySheetResumeSubmissionCategory: number // エントリーシート/履歴書選択区分
  researchOverviewsSubmissionFlg: boolean // 研究概要書提出フラグ
  originalQuestionSubmissionFlg: boolean // オリジナル設問提出フラグ
  fileSubmissionFlg: boolean // ファイル提出フラグ
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string  // 更新日時 
}
/*
 * お問い合わせ先データ
 */
export interface MCBHS310ReferenceContentInfo {
  inquiryId: number // お問い合わせ先ID
  inquiryRegisteredName: string// お問い合わせ先名
  companyName: string// 企業名
  departmentNameAndManagerName: string// 部署名／担当者氏名
  telNumber: string// 電話番号
  emailAddress: string// メールアドレス
  hiddenFlg: boolean// 非表示フラグ
  modifiedDate: string// 更新日時
}
/*
 * 署名のデータ
 */
export interface MCBHS310SignatureInfo {
  signSettingId: number	// 署名設定ID
  registrationName: string // 登録名
  signature: string	// 署名 
}
/*
 * 添付ファイルのデータ
 */
export interface AttachmentFileInfo {
  sequence: number	// 連番
  attachmentName: string	// 添付ファイル名
  fileManagementId: string	// ファイル管理ID
  fileSize: string	// ファイルサイズ
}
/*
 * サンクスメールテンプレートデータ
 */
export interface MCBHS310ThanksMessageTemplateInfo {
  thanksMailTemplateId: number  // サンクスメールテンプレートID
  thanksMailTemplateRegisteredName: string // サンクスメールテンプレート件名
  thanksMailTemplateSubject: string // サンクスメールテンプレート件名
  thanksMailTemplateBody: string // サンクスメールテンプレート本文
  modifiedDate: string // 更新日時
}

/*
 * 個人情報の取り扱いデータ
 */
export interface MCBHS310PrivacyPolicyContentInfo {
  myCareerBoxPrivacyPolicyId: number // My CareerBoxに関する個人情報の取り扱いID
  privacyPolicyRegisterdName: string // My CareerBoxに関する個人情報の取り扱い名
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string// 更新日時
}
export interface MCBHS310TargetCriteriaObj {
  entryBoxInfo: [{
    entryBoxId: string
    entryBoxName: string
  }]
  seminarInfo: [{
    seminarId: string
    seminarName: string
  }]
}

export interface MCBHS310LinkResultDto {
  authToken: string
  sourceCompanyCode: string
  url: string
  annualParam: string
}
export interface templateAttachmentInfo {
  templateAttachmentName: string[],
  templateAttachmentSize: number[]
}
export interface AutoSubmissionRequestUseStartTimeDto {
  mediaFlag: string // 媒体フラグ
  startDateStr: string // 開始日
}
export const initReturnDisplayValues: MCBHS310ReturnDisplayOutDto = {
  autoSubmissionRequestSettingId: '', // 自動提出リクエスト設定ID
  autoSubmissionRequestName: '', // 自動提出リクエスト登録名
  destinationSelection: '3', // 送信先区分
  entryBoxList: [], // エントリーボックス情報
  seminarList: [], // セミナー情報
  entryBoxIds: [], // 対象者設定(エントリーボックスID)
  seminarIds: [], // 対象者設定(セミナーID)
  targetCriteria: '', // 対象者設定(自動提出リクエスト条件)
  useSetting: '1', // 利用設定フラグ
  useSettingStartDate: '', // 利用設定_利用開始日時
  useSettingEndDate: '', // 利用設定_利用終了日時
  submissionRequestTemplateInfoList: [], // 提出リクエストテンプレート情報
  submissionRequestTemplateId: '', // 提出リクエストテンプレート選択ID
  submissionRequestTemplateName: '', // 提出リクエストテンプレート選択名
  submissionsContentList: [], // 提出物内容リスト
  submissionsSelectId: '', // 提出物内容選択ID
  submissionsName: '', // 提出物内容（企業管理用）
  submissionsNameStudent: '', // 提出物内容（学生画面表示用）
  submissionsNames: '', // 提出物
  submissionsEntryFlg: '', // 提出物エントリシートフラグ
  submissionsResumeFlg: '', // 提出物履歴書フラグ
  submissionsResearchFlg: '', // 提出物研究概要書フラグ
  submissionsOriginalFlg: '', // 提出物オリジナル設問フラグ
  submissionsFileFlg: '', // 提出物ファイル提出フラグ
  limitDateDetermineFlag: '0', // 提出締切_種別
  determineDateTime: '', // 提出締切日指定日時
  requestDayTimeLaterDay: '', // 依頼日から○日後
  requestDayTimeToTime: '', // 依頼日から△:00まで
  anytimeReceptionEndDateTime: '', // 随時受付終了日時
  reRequestReceptFlag: '0', // 再提出受付フラグ
  senderCompanyName: '', // 送信元会社名の指定
  senderMailAddress: '', // 返信先メールアドレス
  referenceContentList: [], // お問い合わせ先リスト
  referenceContentId: '', // お問い合わせ先選択ID
  referenceContentName: '', // お問い合わせ先選択名
  referenceContentCompanyName: '', // お問い合わせ先企業名
  referenceContentDepartmentNameAndManagerName: '', // お問い合わせ先部署名／担当者氏名
  referenceContentTelephoneNumber: '', // お問い合わせ先電話番号
  referenceContentMailAddress: '', // お問い合わせ先メールアドレス
  signatureList: [], // 署名リスト
  requestSubject: '', // 件名
  requestText: '', // 本文
  attachmentFileList: [], // 添付ファイル情報
  homePageTitle1: '', // ホームページタイトル1
  homePageUrl1: '', // ホームページURL1
  homePageTitle2: '', // ホームページタイトル2
  homePageUrl2: '', // ホームページURL2
  homePageTitle3: '', // ホームページタイトル3
  homePageUrl3: '', // ホームページURL3
  thanksMessageSetFlag: '1', // サンクスメッセージ設定フラグ
  thanksMessageTemplateList: [], // サンクスメールテンプレート内容リスト
  thanksMessageTemplateId: '', // サンクスメールテンプレートID
  thanksMessageTemplateName: '', // サンクスメールテンプレート名
  thanksSubject: '', // （サンクス）件名
  thanksText: '', // （サンクス）本文
  privacyPolicyContentList: [], // 個人情報の取り扱い内容リスト
  personalDataHandlingId: '', // 個人情報の取り扱い選択ID
  personalDataHandlingName: '', // 個人情報の取り扱い名
  exampleLink: '', // 文例リンク
  submissionsLink: '', // 提出物内容の作成・確認はこちらリンク
  referenceLink: '', // お問い合わせ先の作成・確認はこちらリンク
  requestExampleLink: '', // 提出リクエストメールの文例集を見るリンク
  thanksMailTemplateLink: '', // サンクスメッセージテンプレートの作成・確認はこちらリンク
  thanksMailExampleLink: '', // サンクスメッセージの文例集を見るリンク
  personalDataHandlingLink: '', // 個人情報の取り扱いの作成・確認はこちらリンク
  authToken: '', // MCB認証トークン
  annual: '', // 年度
  annualList: '', // 対象年度リスト
  annualCs: '', // チェックサム
  forbiddenWordsList: [], // 禁止語リスト
  autoSubmissionRequestUseStartTime: [], // 自動提出リクエスト不可期間
  isManagementOffice: false, // 運営事務局判定
}
export const AttachmentFileInitialValues: AttachmentFileInfo[] = [{
  sequence: 0,	// 連番
  attachmentName: 'ファイルを選択',	// 添付ファイル名
  fileManagementId: '',	// ファイル管理ID
  fileSize: '',	// ファイルサイズ
}]

export const initialopenModal = {
  openCheckModal: false,
  modalMessage: '',
}

export const reRequestOptionList = [
  { value: '1', label: '受け付ける' },
  { value: '0', label: '受け付けない' },
]

export const deadlineAfterDaySuggest = [
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
];

export const deadlineAfterDayTimeSuggest = [
  { label: '00', value: '00' },
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
];

export const settingThanksEmailList = [
  { value: '1', label: '設定する' },
  { value: '0', label: '設定しない' },
]
export const suffixCheck = [
  'ai', 'art', 'doc', 'fla', 'gif', 'jpg', 'jpeg',
  'lzh', 'mpeg', 'mpg', 'pdf', 'png', 'pps', 'ppt',
  'psd', 'txt', 'xls', 'zip', 'docx', 'xlsx'
]